import React, { useState } from 'react';
import { Grid, Link, Snackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Link as RouteLink } from 'react-router-dom';
import { LayoutForm, TextForm } from './Layout';
import { Teams } from '../../api';
import { ROUTES } from "../../Routes/const";

export const Forgot = () => {
  const [body, setBody] = useState({
    email: ""
  });
  const [err, setErr] = useState("");
  const [isSendingForm, setIsSendingForm] = useState(false);

  const [snackMessage, setSnackMessage] = useState("");
  const handleClose = () => {
    setSnackMessage("");
  }

  const handleChange = (ev)=>{
    if(err !== '')
      setErr('');
    const value = ev.target.value;
    setBody({
      ...body,
      [ev.target.name]: value
    });
  }

  const submitForm = (ev) => {
    ev.preventDefault();
    if(isSendingForm) return;
    setErr('')
    setIsSendingForm(true);
    Teams.post('resetpassword', body)
    .then(res => {
      setIsSendingForm(false);
      setSnackMessage("Email berhasil terkirim! Periksa inbox email atau folder spam anda.")
    }).catch((err => {
      setIsSendingForm(false);
      if(err.response){
        if(err.response.status === 400)
          setErr(err.response.data.email);
        else
          setErr(err.response.data.message);
      } else
        setErr("Timeout! Server not responding or check your connectivity.");
    }))
  }

  return (
    <LayoutForm
      formType="Forgot"
      submitForm={submitForm}
      isSendingForm={isSendingForm}
      formButtonLabel="Send Email Reset Password"
      bottom={
        <>
        <Grid container justify="center">
          <Grid item>
            <Link component={RouteLink} to={ROUTES.AUTH.LOGIN} variant="body2">
              Sudah punya akun? Masuk
            </Link>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item>
            <Link component={RouteLink} to={ROUTES.AUTH.REGISTER} variant="body2">
                Belum punya akun? Daftar
            </Link>
          </Grid>
        </Grid>
        </>
      }
    >
      <Grid item xs={12}>
        <TextForm
          required
          type="email"
          value={body.email}
          onChange={handleChange}
          name="email"
          error={err !== ''}
          helperText={err}
          label="Email"
          autoFocus
        />
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackMessage !== ''}
        autoHideDuration={10000}
        onClose={handleClose}
        message={snackMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </LayoutForm>
  );
}