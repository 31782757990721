export const ROUTES = {
    HOMEPAGE: {
        MAIN: '/',
        POSTER: '/poster',
        PETUNJUK: '/petunjuk',
        FAQ: '/faq',
        MERCH: '/merch',
        WEBINAR: '/webinar',
        PENGUMUMAN: '/pengumuman',
        SEMIFINAL: '/semifinal'
    },
    AUTH: {
        LOGIN: '/sso/login',
        REGISTER: '/sso/register',
        FORGOT: '/sso/forgot',
        RESET: '/sso/reset/:token',
        VERIFY_RESEND: '/sso/verify',
        VERIFY: '/sso/verify/:token'
    },
    DASHBOARD: {
        PESERTA: {
            OVERVIEW: '/dashboard/data',
            DATA_TIM: '/dashboard/tim',
            SEMIFINAL_PESERTA: '/dashboard/semifinal',
            SERTIFIKAT: '/dashboard/sertifikat'
        },
        ADMIN: {
            ALL_TEAMS: '/dashboard/admin/all',
            VERIFY_TEAMS: '/dashboard/admin/verify',
            VERIFY_TEAMS_QUERY: '/dashboard/admin/verify/:id',
            ACTIVE_TEAMS: '/dashboard/admin/active',
            ACTIVE_TEAMS_QUERY: '/dashboard/admin/active/:id',
            SEMIFINAL_TEAMS: '/dashboard/admin/semifinal',
            SEMIFINAL_ADMIN: '/dashboard/admin/semifinaladmin'
        }
    },
}

export const getRoutedParams = (route, params) => {
    let res = route;
    for(const i in params){
        res = res.replaceAll(`:${i}`, params[i]); 
    }
    return res;
}
