import { Dashboard } from '../Dashboard';
import { dashboardRoutes } from '../../../Routes/routes';

export const UserDashboard = (props) => {
    return (
        <Dashboard
            listItem={dashboardRoutes}>
                { props.children }
        </Dashboard>
    );
}

export default UserDashboard;