import React from 'react';
import { Box, Container, Grid, Link, Paper, TextField, Typography } from '@material-ui/core';
import logo from "../../image/logo-impact-putih.png";
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouteLink } from 'react-router-dom';
import { ButtonProgress } from "../../Components/ButtonProgress";

import styles from './Layout.module.css';

function Copyright() {
    return (
      <Typography variant="body2" style={{color: "#ffffff"}} align="center">
        {'Copyright © '}
        <Link component={RouteLink} color="inherit" to="/">
          IMPACT by STEI'20
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "#fff",
        padding: theme.spacing(3)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        padding: theme.spacing(8, 0, 4, 0)
    },
    primary: {
        color: "#A05969"
    }
}));

export const TextForm = ({ type, ...rest }) => {
    return (
        <TextField
            variant="outlined"
            fullWidth
            type={type || "text"}
            { ...rest }
        />
    );
}

export const LayoutForm = (props) => {
    const classes = useStyles();
    return (
        <div className={styles.root}>
            <Container component="main" maxWidth="xs">
            <RouteLink to="/" className={classes.logo}>
                <img src={logo} alt="logo" className={styles.centerLogo} />
            </RouteLink>
            <Paper className={classes.paper} elevation={8}>
                {props.body}
                <form className={classes.form}>
                    <Grid container spacing={2}>
                        {props.children}
                    </Grid>
                    <ButtonProgress
                        type="submit"
                        onClick={props.submitForm}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={props.isSendingForm}
                    >
                        {props.formButtonLabel}
                    </ButtonProgress>
                    { props.bottom }
                </form>
            </Paper>
            <Box mt={2} >
                <Copyright />
            </Box>
            </Container>
        </div>
    );
}
