import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Instagram } from "../../image/instagram-logo.svg";
import Utama1 from "../../image/utama/1.png";
import Utama2 from "../../image/utama/2.png";
import Utama3 from "../../image/utama/3.png";
import Sponsor1 from "../../image/sponsor/1.png";
import Sponsor2 from "../../image/sponsor/2.png";
import Media1 from "../../image/mediapartner/1.png";
import Media2 from "../../image/mediapartner/2.png";
import Media3 from "../../image/mediapartner/3.png";
import Media4 from "../../image/mediapartner/4.png";
import Media5 from "../../image/mediapartner/5.png";
import Media6 from "../../image/mediapartner/6.png";
import Media7 from "../../image/mediapartner/7.png";
import Media8 from "../../image/mediapartner/8.png";
import Media9 from "../../image/mediapartner/9.png";
import Media10 from "../../image/mediapartner/10.png";
import Media11 from "../../image/mediapartner/11.png";
import Media12 from "../../image/mediapartner/12.png";
import Media13 from "../../image/mediapartner/13.png";
import Media14 from "../../image/mediapartner/14.png";
import Support1 from "../../image/support/1.png";
import Support2 from "../../image/support/2.png";
import Support3 from "../../image/support/3.PNG";
import Support4 from "../../image/support/4.png";
import AOS from "aos";
import "aos/dist/aos.css";

// media list
const utamaList = [Utama1, Utama3, Utama2];
const sponsorList = [Sponsor1, Sponsor2];
const mediaList = [
  Media9,
  Media12,
  Media8,
  Media11,
  Media2,
  Media4,
  Media14,
  Media1,
  Media3,
  Media10,
  Media5,
  Media6,
  Media7,
  Media13,
];
const supportList = [Support2, Support1, Support3, Support4];

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#A05969",
    padding: theme.spacing(1),
  },
  paragraph: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    padding: theme.spacing(0.25),
  },
  svg: {
    height: "16px",
    width: "auto",
  },
  typography: {
    color: "#fff",
    fontFamily: "FuturaMd",
  },
  title: {
    color: "#fff",
    fontFamily: "Azonix",
  },
  span: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    margin: theme.spacing(0, 0),
  },
  spanWrap: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    flexWrap: "wrap",
    margin: theme.spacing(1, 0),
  },
  column: {
    flex: "50%",
    padding: "10px",
    textAlign: "center",
    verticalAlign: "center",
    color: "#fff",
    fontFamily: "FuturaMd",
  },
  utamaMobile: {
    height: "auto",
    width: "auto",
    maxHeight: "60px",
    maxWidth: "150px",
    margin: "20px",
  },
  medparMobile: {
    height: "auto",
    width: "auto",
    maxHeight: "40px",
    maxWidth: "100px",
    margin: "15px",
  },
  supportMobile: {
    height: "auto",
    width: "auto",
    maxHeight: "45px",
    maxWidth: "112.5px",
    margin: "15px",
  },
  sponsorMobile: {
    height: "auto",
    width: "auto",
    maxHeight: "50px",
    maxWidth: "125px",
    margin: "15px",
  },
  left: {
    width: "33%",
    justifyContent: "flex-start",
    margin: theme.spacing(1, 0),
    alignContent: "center",
    flexGrow: 1,
  },
  center: {
    width: "34%",
    justifyContent: "space-between",
    margin: theme.spacing(1, 0),
    alignContent: "center",
    flexGrow: 1,
  },
  right: {
    width: "33%",
    justifyContent: "flex-end",
    margin: theme.spacing(1, 0),
    alignContent: "center",
    flexGrow: 1,
  },
}));

export const Footer = () => {
  const [state, setState] = useState({
    mobileView: false,
  });
  
  const { mobileView } = state;

  useEffect(() => {
    AOS.init({ duration: 1500, once: true });
  }, []);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const classes = useStyles();

  const UtamaImagesMobile = () => {
    return (
      <div className={classes.spanWrap}>
        {utamaList.map((media, idx) => {
          return (
            <img
              src={media}
              alt="Utama"
              key={idx}
              className={classes.utamaMobile}
            />
          );
        })}
      </div>
    );
  };

  const SponsorImagesMobile = () => {
    return (
      <div className={classes.spanWrap}>
        {sponsorList.map((media, idx) => {
          return (
            <img
              src={media}
              alt="Sponsor"
              key={idx}
              className={classes.sponsorMobile}
            />
          );
        })}
      </div>
    );
  };

  const MedParImagesMobile = () => {
    return (
      <div className={classes.spanWrap}>
        {mediaList.map((media, idx) => {
          return (
            <img
              src={media}
              alt="Medpart"
              key={idx}
              className={classes.medparMobile}
            />
          );
        })}
      </div>
    );
  };

  const SupportImagesMobile = () => {
    return (
      <div className={classes.spanWrap}>
        {supportList.map((media, idx) => {
          return (
            <img
              src={media}
              alt="Support"
              key={idx}
              className={classes.supportMobile}
            />
          );
        })}
      </div>
    );
  };

  const SponsorTitle = () => {
    return (
      <div className={classes.span}>
        <Typography
          className={classes.title}
          variant={window.innerWidth < 900 ? "h6" : "h5"}
        >
          Sponsored by
        </Typography>
      </div>
    );
  };

  const SupportTitle = () => {
    return (
      <div className={classes.span}>
        <Typography
          className={classes.title}
          variant={window.innerWidth < 900 ? "h6" : "h5"}
        >
          Supported by
        </Typography>
      </div>
    );
  };

  const MedParTitle = () => {
    return (
      <div className={classes.span}>
        <Typography
          className={classes.title}
          variant={window.innerWidth < 900 ? "h6" : "h5"}
        >
          Media Partners
        </Typography>
      </div>
    );
  };

  const Contacts = () => {
    return (
      <div className={classes.span}>
        <Typography className={classes.column}>
          Nissha : <br></br>
          LINE : nisshakinaya_<br></br>
          Phone : 085893626187
        </Typography>
        <Typography className={classes.column}>
          Dariel : <br></br>
          LINE : darielma25<br></br>
          Phone : 081281813775
        </Typography>
      </div>
    );
  };

  const ContactTitle = () => {
    return (
      <div className={classes.span}>
        <Typography
          className={classes.title}
          variant={window.innerWidth < 900 ? "h6" : "h5"}
        >
          Contact Person
        </Typography>
      </div>
    );
  };

  const AlamatTitle = () => {
    return (
      <div className={classes.span}>
        <Typography
          className={classes.title}
          variant={window.innerWidth < 900 ? "h6" : "h5"}
        >
          Alamat
        </Typography>
      </div>
    );
  };

  const Alamat = () => {
    return (
      <div className={classes.span}>
        <Typography className={classes.column}>
          Sekretariat HMIF ITB<br></br>
          Gd. Benny Subianto/Labtek V Lt. 1<br></br>
          Jalan Ganesha 10, Bandung 40132
        </Typography>
      </div>
    );
  };

  const FooterOfFooter = () => {
    return (
      <div>
        <div className={classes.paragraph}>
          <Typography className={classes.typography}>
            IMPACT 2.0 by STEI ITB 2020
          </Typography>
        </div>
        <div className={classes.paragraph}>
          <Typography className={classes.typography}>
          {"Follow us on "}
          </Typography>
        </div>
        <div className={classes.paragraph}>
          <Typography className={classes.typography}>
            <Instagram className={classes.svg} />{" "}
            <a
              className={classes.typography}
              href="https://instagram.com/impact_itb"
              target="_blank"
              rel="noreferrer"
            >
              {"@impact_itb"}
            </a>
            {" and "}
            <Instagram className={classes.svg} />{" "}
            <a
              className={classes.typography}
              href="https://www.instagram.com/stei20itb/"
              target="_blank"
              rel="noreferrer"
            >
              {"@stei20itb"}
            </a>
          </Typography>
        </div>
      </div>
   );
  };     

  const displayMobile = () => {
    return (
      <div className={classes.root}>
        <UtamaImagesMobile />
        <SponsorTitle />
        <SponsorImagesMobile />
        <SupportTitle />
        <SupportImagesMobile />
        <MedParTitle />
        <MedParImagesMobile />
        <ContactTitle />
        <Contacts />
        <AlamatTitle />
        <Alamat />
        <FooterOfFooter />
      </div>
    );
  };

  const displayDesktop = () => {
    return (
      <div className={classes.root}>
        <UtamaImagesMobile />
        <div className={classes.span}>
          <div className={classes.left}>
            <ContactTitle />
            <Contacts />
            <AlamatTitle />
            <Alamat />
          </div>
          <div className={classes.center}>
            <SponsorTitle />
            <SponsorImagesMobile />
            <SupportTitle />
            <SupportImagesMobile />
          </div>
          <div className={classes.right}>
            <MedParTitle />
            <MedParImagesMobile />
          </div>
        </div>
        <FooterOfFooter />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {mobileView ? displayMobile() : displayDesktop()}
    </div>
  );
};

export default Footer;
