import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../../../Components/Navbar/navbar';
import Footer from '../../../Components/Footer/footer';
// import PengumumanPenyisihan from "../../../image/pengumuman-penyisihan.png";
import PengumumanSemifinal from "../../../image/pengumuman-semifinal.png";

// styles
const useStyles = makeStyles((theme) => ({
    root: {
        background: "#FAEBE8",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    container: {
        margin: theme.spacing(2, 4),
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    heading: {
        color: "#A05969",
        fontFamily: "Azonix",
    },
    svg: {
        maxWidth: "100%",
        height: "auto",
    },
    span: {
        width: "100%",
        height: "auto",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        margin: theme.spacing(2,0),
    }
}))

export const Pengumuman = () => {
    const classes = useStyles();

    // cek state untuk display apakah mobile atau desktop
    const [state, setState] = useState({
        mobileView: false,
    });

    const { mobileView } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
            ? setState((prevState) => ({ ...prevState, mobileView: true }))
            : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);
    
    // display
    const displayMobile = () => {
        return(
            <div className={classes.container}>
                <div className={classes.span}>
                    <img src={PengumumanSemifinal} alt="pengumuman" className={classes.svg}/>
                </div>
            </div>
        )
    }
    const displayDesktop = () => {
        return (
            <div className={classes.container}>
                <div className={classes.span}>
                    <img src={PengumumanSemifinal} alt="pengumuman" className={classes.svg} style={{paddingTop: "20px"}}/>
                </div>
            </div>
        )
    }
    
    return (
        <div className={classes.root}>
            <Navbar style={{flexShrink: 0}} />
            {mobileView ? displayMobile() : displayDesktop()}
            <Footer style={{flexShrink: 0}} />
        </div>
    )
};