import { Typography, Grid, Paper } from "@material-ui/core";
import React from "react";
import styles from "./Lomba.module.css";

export const Lomba = () => {
  return (
    <div className={styles.root}>
      <Typography className={styles.title} variant="h4">
        Mata Lomba
      </Typography>
      <Typography className={styles.disclaimer} variant="body1">
        *ketiga mata lomba diujikan secara bersamaan
      </Typography>
      <Grid container className={styles.container} justify="space-around">
        <Grid item sm={12} md={4}>
          <Paper className={styles.paper}>
            <Typography className={styles.subJudul} variant="h5">
              Fisika
            </Typography>
            <ul className={styles.list}>
              <li>Kinematika, Dinamika, dan Energi</li>
              <li>Fluida, Gelombang, dan Osilasi</li>
              <li>Gas dan Termodinamika</li>
              <li>Listrik dan Magnet</li>
            </ul>
          </Paper>
        </Grid>
        <Grid item sm={12} md={4}>
          <Paper className={styles.paper}>
            <Typography className={styles.subJudul} variant="h5">
              Matematika
            </Typography>
            <ul className={styles.list}>
              <li>Aljabar</li>
              <li>Kombinatorika</li>
              <li>Geometri</li>
              <li>Teori Bilangan</li>
            </ul>
          </Paper>
        </Grid>
        <Grid item sm={12} md={4}>
          <Paper className={styles.paper}>
            <Typography className={styles.subJudul} variant="h5">
              Komputer
            </Typography>
            <ul className={styles.list}>
              <li>Teori Bilangan</li>
              <li>Kombinatorika</li>
              <li>Logika</li>
              <li>Pseudocode</li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
