import React, { useEffect, useState } from 'react';
import { Paper, Grid, TextField, Button, Snackbar, IconButton, makeStyles, Typography } from '@material-ui/core';
import styles from './DataActive.module.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AdminDashboard } from '../../AdminDashboard';
import {Link as RouterLink} from 'react-router-dom';
import { ROUTES } from "../../../../../Routes/const";
import Title from '../../../Components/Title';
import { Close } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { Semifinal, Teams } from '../../../../../api';

const useStyles = makeStyles((theme) => ({
    guidebutton: {
        backgroundColor: "#f57270",
        "&:hover": {
            backgroundColor: "#f2514e"
        },
        color: "#fff",
        width: "100%"
    }
}))

export const DataActive = () => {
    const classes = useStyles();

    const [info, setInfo] = useState("");
    const handleClose = () => {
        setInfo("");
    }
    const { id } = useParams();
    const [isSemifinal, setIsSemifinal] = useState(null);
    const [data, setData] = useState({
        _id: '',
        name: '',
        school: '',
        email: '',
        members: ['', ''],
        phone: ['', ''],
        idline: ['', ''],
        kartu: ['', ''],
        bukti: '',
        teamstatus: ''
    });
    const [preview, setPreview] = useState({
        kartupelajar1: '',
        kartupelajar2: '',
        bukti: ''
    });

    useEffect(()=>{
        function readFile(f, key) {
            let reader = new FileReader();
            reader.onloadend = (ev) => {
                setPreview(p => ({
                    ...p,
                    [key]: ev.target.result
                }))
            }
            reader.onerror = () => {
                setInfo("File tidak valid!");
            }
            reader.readAsDataURL(f);
        }
        Teams.get(`/${id}`)
        .then((res) => {
            for(let i = 0; i < 2; i++){
                Teams.get(`/fotokartupelajar/${id}/${i}`, {responseType: 'blob'})
                .then((res) => {
                    readFile(res.data, `kartupelajar${i+1}`);
                })
            }
            Teams.get(`/buktipembayaran/${id}`, {responseType: 'blob'})
            .then((res) => {
                readFile(res.data, 'bukti')
            })
            setData(res.data);
        });
        Semifinal.get(`/${id}`)
        .then((res) => {
            setIsSemifinal(true);
        }).catch((err) => {
            if(err.response && err.response.status === 404) {
                setIsSemifinal(false);
            }
        })
    },[id]);

    const DownloadKartu1 = () => {
        return(
            <Button href={preview.kartupelajar1} download={data.members[0]} className={classes.guidebutton}>
                Download Kartu Pelajar {data.members[0]}
            </Button>
        )
    }

    const DownloadKartu2 = () => {
        return(
            <Button href={preview.kartupelajar2} download={data.members[1]} className={classes.guidebutton}>
                Download Kartu Pelajar {data.members[1]}
            </Button>
        )
    }

    const DownloadBukti = () => {
        return(
            <Button href={preview.bukti} download={data.name} className={classes.guidebutton}>
                Download Bukti Pembayaran {data.name}
            </Button>
        )
    }

    const ToggleTeamSemifinal = async () => {
        if (isSemifinal === false){
            setIsSemifinal(null);
            Semifinal.post(`/${id}/add`).then((res) => {
                setIsSemifinal(true);
                setInfo("Berhasil mendaftarkan tim ke semifinal!")
            }).catch((err) => {
                setIsSemifinal(false);
                setInfo("Terjadi kesalahan saat mendaftarkan tim ke semifinal")
            });
        } else if (isSemifinal === true) {
            setIsSemifinal(null);
            Semifinal.delete(`/${id}/delete`).then((res) => {
                setIsSemifinal(false);
                setInfo("Berhasil menghapus tim di semifinal!");
            }).catch((err) => {
                setIsSemifinal(true);
                setInfo("Terjadi kesalahan saat menghapus tim di semifinal");
            })
        }
    }

    return (
        <AdminDashboard>
            <RouterLink to={ROUTES.DASHBOARD.ADMIN.ACTIVE_TEAMS} style={{textDecoration: 'none'}}>
            <Button disableRipple className={styles.title}>
                <ArrowBackIcon className={styles.iconBack}/> Back
            </Button>
            </RouterLink>
            <Grid container>
                <Grid item xs={12} className={styles.container}>
                    <Title>Data Tim</Title>
                    <form className={styles.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Kode Registrasi"
                                    fullWidth
                                    value={data._id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nama Tim"
                                    fullWidth
                                    value={data.name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Asal Sekolah"
                                    fullWidth
                                    value={data.school}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <TextField
                                label="Email"
                                fullWidth
                                value={data.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} className={styles.container}>
                    <Title>Data Anggota</Title>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <form className={styles.form} noValidate>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextField value={data.members[0]} label="Nama Anggota 1" fullWidth disabled/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField value={data.phone[0]} label="No. Handphone" fullWidth disabled/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField value={data.idline[0]} label="ID Line" fullWidth disabled/>
                                    </Grid>
                                </Grid>
                                </form>
                            </Paper>
                        </Grid>
                        <Grid item sm={6} xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <form className={styles.form} noValidate>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextField value={data.members[1]} label="Nama Anggota 2" fullWidth disabled/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField value={data.phone[1]} label="No. Handphone" fullWidth disabled/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField value={data.idline[1]} label="ID Line" fullWidth disabled/>
                                    </Grid>
                                </Grid>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid item xs={12} className={styles.container}>
                    <Title>Kartu Pelajar</Title>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <img src={preview.kartupelajar1} className={styles.image} alt="Kartu Pelajar Anggota 1"/>
                                <DownloadKartu1 />
                            </Paper>
                        </Grid>
                        <Grid item sm={6} xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <img src={preview.kartupelajar2} className={styles.image} alt="Kartu Pelajar Anggota 2"/>
                                <DownloadKartu2 />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={styles.container}>
                    <Title>Bukti</Title>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <img src={preview.bukti} className={styles.image}  alt="Bukti Pembayaran"/>
                                <DownloadBukti />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={styles.container}>
                    <Title>Team Semifinal</Title>
                    <Grid container spacing={1}>
                        <Typography color={isSemifinal?"primary":"error"}>Status: {isSemifinal? "Sudah terdaftar di semifinal":"Belum terdaftar di semifinal"}</Typography>
                        <Grid item sm={12} xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <Button disabled={(isSemifinal === null)} onClick={ToggleTeamSemifinal} className={classes.guidebutton}>
                                    {isSemifinal ? "Hapus":"Tambah"} Team {data.name} di Semifinal
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={info !== ''}
                autoHideDuration={5000}
                onClose={handleClose}
                message={info}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
        </AdminDashboard>
    )
}
