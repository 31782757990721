import React, { useEffect, useState } from 'react';
import { Paper, Grid, TextField, Button, Snackbar, IconButton,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './DataVerif.module.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AdminDashboard } from '../../AdminDashboard';
import {Link as RouterLink} from 'react-router-dom';
import { ROUTES } from "../../../../../Routes/const";
import Title from '../../../Components/Title';
import { Cancel, Done, Close } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { Teams } from '../../../../../api';

const useStyles = makeStyles((theme) => ({
    guidebutton: {
        backgroundColor: "#f57270",
        "&:hover": {
            backgroundColor: "#f2514e"
        },
        color: "#fff",
        width: "100%"
    }
}))

export const DataVerif = () => {
    const classes = useStyles();

    const [info, setInfo] = useState("");
    const handleClose = () => {
        setInfo("");
    }
    const { id } = useParams();
    const [data, setData] = useState({
        _id: '',
        name: '',
        school: '',
        email: '',
        members: ['', ''],
        phone: ['', ''],
        idline: ['', ''],
        kartu: ['', ''],
        bukti: '',
        teamstatus: ''
    });
    const [preview, setPreview] = useState({
        kartupelajar1: '',
        kartupelajar2: '',
        bukti: ''
    });
    const dialogInitialState = {
        open: false,
        title: "",
        body: "",
        type: "",
        actions: null
    }
    const [dialog, setDialog] = useState(dialogInitialState);
    const closeDialog = () => {
        setDialog(dialogInitialState)
    }

    const [reason, setReason] = useState("");
    const acceptDialog = () => {
        setDialog({
            open: true,
            title: "Terima Verifikasi",
            body: "Anda yakin ingin menerima verifikasi dari tim ini?",
            actionLabel: "Verifikasi Tim"
        })
    }
    const changeReason = (e) => {
        setReason(e.target.value);
    }
    const declineDialog = () => {
        setDialog({
            open: true,
            title: "Tolak Verifikasi",
            body: `Anda akan menolak verifikasi tim ini. Berikan alasan anda terhadap penolakan verifikasi ini.
            (e.g. "Kartu pelajar kurang", "Nama vulgar", "Silahkan kontak panitia lebih lanjut di no: ...", dst.)`,
            type: "decline",
            actionLabel: "Tolak Verifikasi"
        })
    }

    const submitAccept = () => {
        closeDialog();
        Teams.post(`/activate/${id}`)
        .then((res) => {
            setInfo(res.data.message);
            setData({
                ...data,
                teamstatus: 'activated'
            });
        })
        .catch((err) => {
            if(err.response){
                setInfo("Terjadi kesalahan saat mengaktivasi tim.");
            } else {
                setInfo("Timeout! Server not responding or check your connectivity.");
            }
        });
    }
    const submitDecline = () => {
        if(reason === ''){
            setInfo('Alasan anda kosong. Silahkan isi alasan terlebih dahulu.');
        } else {
            closeDialog();
            Teams.post(`/decline/${id}`, {reason: reason})
            .then((res) => {
                setInfo(res.data.message);
                setData({
                    ...data,
                    teamstatus: 'declined'
                });
            })
            .catch((err) => {
                if(err.response){
                    setInfo("Terjadi kesalahan saat mmenolak verifikasi tim.");
                } else {
                    setInfo("Timeout! Server not responding or check your connectivity.");
                }
            });
        }
    }

    const DownloadKartu1 = () => {
        return(
            <Button href={preview.kartupelajar1} download={data.members[0]} className={classes.guidebutton}>
                Download Kartu Pelajar {data.members[0]}
            </Button>
        )
    }

    const DownloadKartu2 = () => {
        return(
            <Button href={preview.kartupelajar2} download={data.members[1]} className={classes.guidebutton}>
                Download Kartu Pelajar {data.members[1]}
            </Button>
        )
    }

    const DownloadBukti = () => {
        return(
            <Button href={preview.bukti} download={data.name} className={classes.guidebutton}>
                Download Bukti Pembayaran {data.name}
            </Button>
        )
    }

    useEffect(()=>{
        function readFile(f, key) {
            let reader = new FileReader();
            reader.onloadend = (ev) => {
                setPreview(p => ({
                    ...p,
                    [key]: ev.target.result
                }))
            }
            reader.onerror = () => {
                setInfo("File tidak valid!");
            }
            reader.readAsDataURL(f);
        }
        Teams.get(`/${id}`)
        .then((res) => {
            for(let i = 0; i < 2; i++){
                Teams.get(`/fotokartupelajar/${id}/${i}`, {responseType: 'blob'})
                .then((res) => {
                    readFile(res.data, `kartupelajar${i+1}`);
                })
            }
            Teams.get(`/buktipembayaran/${id}`, {responseType: 'blob'})
            .then((res) => {
                readFile(res.data, 'bukti')
            })
            setData(res.data);
        });
    },[id]);

    return (
        <AdminDashboard>
            <RouterLink to={ROUTES.DASHBOARD.ADMIN.VERIFY_TEAMS} style={{textDecoration: 'none'}}>
            <Button disableRipple className={styles.title}>
                <ArrowBackIcon className={styles.iconBack}/> Back
            </Button>
            </RouterLink>
            <Grid container>
                <Grid item xs={12} className={styles.container}>
                    <Title>Data Tim</Title>
                    <form className={styles.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Kode Registrasi"
                                    fullWidth
                                    value={data._id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nama Tim"
                                    fullWidth
                                    value={data.name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Asal Sekolah"
                                    fullWidth
                                    value={data.school}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <TextField
                                label="Email"
                                fullWidth
                                value={data.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} className={styles.container}>
                    <Title>Data Anggota</Title>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <form className={styles.form} noValidate>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextField value={data.members[0]} label="Nama Anggota 1" fullWidth disabled/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField value={data.phone[0]} label="No. Handphone" fullWidth disabled/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField value={data.idline[0]} label="ID Line" fullWidth disabled/>
                                    </Grid>
                                </Grid>
                                </form>
                            </Paper>
                        </Grid>
                        <Grid item sm={6} xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <form className={styles.form} noValidate>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextField value={data.members[1]} label="Nama Anggota 2" fullWidth disabled/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField value={data.phone[1]} label="No. Handphone" fullWidth disabled/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField value={data.idline[1]} label="ID Line" fullWidth disabled/>
                                    </Grid>
                                </Grid>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid item xs={12} className={styles.container}>
                    <Title>Kartu Pelajar</Title>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <img src={preview.kartupelajar1} className={styles.image} alt="Kartu Pelajar Anggota 1"/>
                                <DownloadKartu1 />
                            </Paper>
                        </Grid>
                        <Grid item sm={6} xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <img src={preview.kartupelajar2} className={styles.image} alt="Kartu Pelajar Anggota 2"/>
                                <DownloadKartu2 />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={styles.container}>
                    <Title>Bukti</Title>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={styles.inputBox}>
                            <Paper className={styles.paper}>
                                <img src={preview.bukti} className={styles.image}  alt="Bukti Pembayaran"/>
                            </Paper>
                            <DownloadBukti />
                        </Grid>
                    </Grid>
                </Grid>
                { data.teamstatus === 'verified' &&
                <Grid item xs={12}>
                    <Grid container direction='row'>
                        <Grid item xs={6}>
                            <Button startIcon={<Cancel />} onClick={declineDialog} variant="contained" color="secondary" fullWidth>
                                Decline
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button startIcon={<Done />} onClick={acceptDialog} variant="contained" color="primary" fullWidth>
                                Accept
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={info !== ''}
                autoHideDuration={5000}
                onClose={handleClose}
                message={info}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
            <Dialog open={dialog.open} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialog.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialog.body}
                    </DialogContentText>
                    {dialog.type === 'decline' &&
                    <TextField
                        autoFocus
                        margin="dense"
                        id="reason"
                        label="Alasan Penolakan"
                        type="text"
                        value={reason}
                        onChange={changeReason}
                        multiline
                        fullWidth
                    />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary" autoFocus>Batal</Button>
                    {dialog.type === 'decline' ?
                        <Button onClick={submitDecline} color="secondary" autoFocus>{dialog.actionLabel}</Button>:
                        <Button onClick={submitAccept} color="secondary" autoFocus>{dialog.actionLabel}</Button>
                    }
                </DialogActions>
            </Dialog>
        </AdminDashboard>
    )
}
