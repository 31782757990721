import React, {useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, IconButton, TableFooter, Button, Typography } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight, FirstPage as FirstPageIcon,
    LastPage as LastPageIcon } from '@material-ui/icons';
import { AdminDashboard } from '../AdminDashboard';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Teams} from "../../../../api";
import { ROUTES, getRoutedParams } from "../../../../Routes/const";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        color: '#A05969',
        fontWeight: 'bolder',
        fontSize: '2em',
        marginBottom: '.5em',
    },
    rowVerif: {
        color: '#32CD32',
        fontWeight: 'bold',
    },
    rowNotVerif: {
        color: 'red',
        fontWeight: 'bold',
    },
    buttonStyle: {
        border: '1px solid black',
        height: '30px'
    },
});

export const VerifTim = () => {
    const [data, setData] = React.useState({
        totalItems: 0,
        documents: [],
        totalPages: 0,
        currentPage: -1
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    useEffect(() => {
        const fetchTeam = async () => {
            return await Teams.get(`/all/verified/?page=${page}&size=${rowsPerPage}`)
        }
        fetchTeam().then(res => {
            setData(res.data)
        }).catch(err => {
        });
    }, [page, rowsPerPage])

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const classes = useStyles();

    return (
        <AdminDashboard>
            <Typography className={classes.title}>
                Verified Teams
            </Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: 'bold'}}>Nama Tim</TableCell>
                            <TableCell style={{fontWeight: 'bold'}}>Data</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.documents.map((row) => (
                            <TableRow key={row._id}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell className={row.status === 'Verified' ? classes.rowVerif : classes.rowNotVerif}>
                                    <Link to={getRoutedParams(ROUTES.DASHBOARD.ADMIN.VERIFY_TEAMS_QUERY, {'id': row._id})} style={{textDecoration: 'none'}}>
                                        <Button className={classes.buttonStyle}>
                                            Open
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 30, 50]}
                                colSpan={3}
                                count={data.totalItems}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'teams per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </AdminDashboard>
    );
}