import React, {useState} from 'react';
import { LayoutForm } from './Layout';
import { Auth, LogoutComponent, Teams } from '../../api';
import { Grid, Typography, Button, Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export const VerifyResend = () => {
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [info, setInfo] = useState("");

  const handleClose = () => {
    setInfo("");
  }

  const submitForm = (ev) => {
    ev.preventDefault();
    if(isSendingForm) return;
    setIsSendingForm(true);
    Teams.post('verifyemail', {email: Auth.data.email})
    .then(res => {
      setIsSendingForm(false);
      setInfo("Email verifikasi telah terkirim!");
    }).catch((err => {
      setIsSendingForm(false);
      if(err.response){
        setInfo(err.response.data.message);
      } else
        setInfo("Timeout! Server not responding or check your connectivity.");
    }))
  }

  return (
    <LayoutForm
      formType="Verify"
      submitForm={submitForm}
      isSendingForm={isSendingForm}
      body={
        <Grid container justify="center">
          <Grid item>
            <Typography variant="h5">Terimakasih sudah mendaftar di IMPACT 20!</Typography>
            <Typography variant="body2">Silahkan verifikasi email anda terlebih dahulu untuk mengakses dashboard. Tidak menerima email? Klik tombol dibawah ini untuk mengirim kembali email verifikasi.</Typography>
            <Typography variant="caption">Salah input email? Kontak admin atau buat akun baru.</Typography>
          </Grid>
        </Grid>
      }
      formButtonLabel="Kirim Ulang Email Verifikasi"
      bottom={
        <Grid container justify="center">
          <Grid item>
              <LogoutComponent>
                  <Button color="secondary">
                      Logout
                  </Button>
              </LogoutComponent>
          </Grid>
        </Grid>
      }
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={info !== ''}
        autoHideDuration={6000}
        onClose={handleClose}
        message={info}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </LayoutForm>
  );
}