import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

export const ButtonProgress = ({children, ...rest}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Button
              {...rest}
            >
                {children}
            </Button>
            { rest.disabled && <CircularProgress size={24} className={classes.buttonProgress} /> }
        </div>
    );
};
