import React from "react";
import { Route } from "react-router-dom";
import { AuthComponent } from '../api';

const AppRoute = ({ component: Component, path, customProps, restrict, show, redirect, ...rest }) => {
  return (
    <Route path={path} render={
      (props) => {
        if(restrict){
          return (
            <AuthComponent
              show={show}
              redirect={redirect}
            >
              <Component {...props} {...customProps} />
            </AuthComponent>
          );
        } else {
          return (
            <AuthComponent
              show='true'
            >
              <Component {...props} {...customProps} />
            </AuthComponent>
          );
        }
      }
    } {...rest} />
  );
};

export default AppRoute;