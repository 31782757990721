import { Paper, Typography, Grid, TextField, Button, Snackbar, IconButton, makeStyles,
         Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Backdrop,
        CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { Prompt } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styles from './DataTim.module.css';
import Title from '../../Components/Title';
import { Teams, Auth } from '../../../../api';
import UserDashboard from '../UserDashboard';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ImageUpload from '../../../../Components/imageUpload/ImageUpload';

const useStyles = makeStyles((theme) => ({
    removeButton: {
        margin: theme.spacing(1),
        backgroundColor: '#d23232',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#cc1919',
        },
        float: 'right',
        width: '15em',
        height: '3em',
        marginRight: '15px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}))

export const DataTim = () => {
    const classes = useStyles()
    const [isSendingForm, setIsSendingForm] = useState(false);
    const [isDirty, setIsDirty] = useState({
        data: false,
        kartu: false,
        bukti: false
    });
    const [body, setBody] = useState({
        members : ['',''],
        phone : ['',''],
        idline : ['','']
    });
    const [err, setErr] = useState({
        members : ['',''],
        phone : ['',''],
        idline : ['','']
    });
    const dialogInitialState = {
        open: false,
        title: "",
        description: "",
        actionDefault: null,
        defaultLabel: "",
        actionSecondary: null,
        secondaryLabel: ""
    }
    const [dialog, setDialog] = useState(dialogInitialState);

    const openDialog = (title, description, actionDefault=closeDialog, defaultLabel="OK", actionSecondary=undefined, secondaryLabel='') => {
        setDialog({
            open: true,
            title: title,
            description: description,
            actionDefault: actionDefault,
            defaultLabel: defaultLabel,
            actionSecondary: actionSecondary,
            secondaryLabel: secondaryLabel
        })
    }

    const closeDialog = () => {
        setDialog(dialogInitialState);
    }

    const verifyDialog = () => {
        let isValidated = false;
        if(body.members[0] !== '' && body.members[1] !== '' && body.phone[0] !== '' && body.phone[1] !== '' &&
           fileKartuPelajar.kartupelajar[0] !== '' && fileKartuPelajar.kartupelajar[1] !== '' &&
           fileBukti.bukti !== ''){
            isValidated = true;
        }
        if(isDirty.kartu || isDirty.bukti || isDirty.data){
            openDialog("Verifikasi Data",
            "Masih ada data yang belum disimpan. Simpan terlebih dahulu semua data yang telah diubah.")
        } else if(isValidated){
            openDialog("Verifikasi Data",
            "Dengan meminta verifikasi data, anda setuju bahwa data yang sudah diberikan benar adanya serta tidak dapat diubah kembali selama proses verifikasi dan setelah verifikasi anda disetujui. Akan ada email lebih lanjut dikirimkan ke email anda jika verifikasi data anda gagal. Silahkan periksa email atau dashboard anda secara berkala untuk informasi lebih lanjut.",
            closeDialog, "Tidak Setuju",
            submitVerify, "Setuju")
        } else {
            openDialog("Verifikasi Data",
            "Data anda belum lengkap. Silahkan lengkapi data anda terlebih dahulu.")
        }
    }

    const submitVerify = (ev) => {
        ev.preventDefault();
        if(isSendingForm) return;
        closeDialog();
        setIsSendingForm(true);
        const source = axios.CancelToken.source();
        setSource(source);
        setProgress(0);
        Teams.post(`/verification/${Auth.data._id}`, {}, {
            cancelToken: source.token,
            ...progressConfig
        })
        .then((res) => {
            setIsSendingForm(false);
            Auth.data.teamstatus = "verified";
            setInfo(res.data.message);
        })
        .catch((errors) => {
            setIsSendingForm(false);
            if(errors.response){
                setInfo("Terjadi kesalahan saat submit data. Silahkan coba lagi.");
            } else {
                setInfo("Timeout! Server not responding or check your connectivity.");
            }
        })
    }

    const [info, setInfo] = useState("");
    const handleClose = () => {
        setInfo("");
    }

    //Bukti
    const [fileBukti, setFileBukti] = useState({
        bukti : ''
    });
    const [previewBukti, setPreviewBukti] = useState('');
    
    const [fileKartuPelajar, setFileKartuPelajar] = useState({
        kartupelajar : ['','']
    });
    const [previewKartuPelajar1, setPreviewKartuPelajar1] = useState('');
    const [previewKartuPelajar2, setPreviewKartuPelajar2] = useState('');

    const handleChange = (ev)=>{
        const hasil = [body[ev.target.name][0],body[ev.target.name][1]]
        hasil[parseInt(ev.target.getAttribute("data-number"))] = ev.target.value;
        if(!isDirty.data)
            setIsDirty({
                ...isDirty,
                data: true
            });
        setBody({
            ...body,
            [ev.target.name]: hasil
        });
    }

    const submitForm = (ev) => {
        ev.preventDefault();
        if(isSendingForm) return;
        setErr({
            members : ['',''],
            phone : ['',''],
            idline : ['','']
        });
        setIsSendingForm(true);
        const source = axios.CancelToken.source();
        setSource(source);
        setProgress(0);
        Teams.put(`/update/${Auth.data._id}`, body, {
            cancelToken: source.token,
            ...progressConfig
        })
        .then(res => {
            setIsSendingForm(false);
            setInfo("Data berhasil diubah di server!");
            setIsDirty({
                ...isDirty,
                data: false
            });
            updateClientData();
        }).catch((errors) => {
            setIsSendingForm(false);
            if(errors.response){
                setInfo("Terjadi kesalahan saat mengupdate data.");
            } else {
                setInfo("Timeout! Server not responding or check your connectivity.");
            }
        });
    }

    const [progress, setProgress] = useState(0.0);
    const [source, setSource] = useState(null);
    const progressConfig = {
        onUploadProgress: function(progressEvent) {
            setProgress((progressEvent.loaded*100)/progressEvent.total);
        }
    }
    const cancelSubmit = () => {
        source.cancel();
    }

    // Kartu
    const submitKartu = (ev) => {
        ev.preventDefault();
        if (isSendingForm) return;
        const formData = new FormData();
        formData.append('image1', fileKartuPelajar.kartupelajar[0]);
        formData.append('image2', fileKartuPelajar.kartupelajar[1]);
        setIsSendingForm(true);
        const source = axios.CancelToken.source();
        setSource(source);
        setProgress(0);
        Teams.post(`kartupelajar/${Auth.data._id}`, formData, {
            headers : {
                'Content-Type' : 'multipart/form-data'
            },
            cancelToken: source.token,
            ...progressConfig
        })
        .then((res) => {
            setIsSendingForm(false);
            setInfo("Kartu pelajar berhasil disimpan di server.");
            setIsDirty({
                ...isDirty,
                kartu: false
            });
            updateClientData();
        }).catch((errors) => {
            setIsSendingForm(false);
            if(errors.response){
                setInfo("Terjadi kesalahan saat mengupload kartu. Silahkan coba lagi.");
            } else {
                setInfo("Timeout! Server not responding or check your connectivity.")
            }
        });
    };

    const kartuHandleChangeOne = (files) => {
        if(files.length === 0){
            setInfo("File tidak valid! Periksa kembali batasan ukuran dan format file.");
            return;
        }
        let reader = new FileReader();
        reader.onloadend = () => {
            const kartu = [files[0], fileKartuPelajar.kartupelajar[1]];
            setFileKartuPelajar({
                kartupelajar: kartu
            })
            if(!isDirty.kartu)
                setIsDirty({
                    ...isDirty,
                    kartu: true
                });
            setPreviewKartuPelajar1(reader.result);
        }
        reader.onerror = () => {
            setInfo("File tidak valid! Tidak dapat membaca file.");
        }
        reader.readAsDataURL(files[0]);
    };

    const kartuHandleChangeTwo = (files) => {
        if(files.length === 0){
            setInfo("File tidak valid! Periksa kembali batasan ukuran dan format file.");
            return;
        }
        let reader = new FileReader();
        reader.onloadend = () => {
            const kartu = [fileKartuPelajar.kartupelajar[0], files[0]];
            setFileKartuPelajar({
                kartupelajar: kartu
            })
            if(!isDirty.kartu)
                setIsDirty({
                    ...isDirty,
                    kartu: true
                });
            setPreviewKartuPelajar2(reader.result);
        }
        reader.onerror = () => {
            setInfo("File tidak valid! Tidak dapat membaca file.");
        }
        reader.readAsDataURL(files[0]);
    };

    const removeKartu = () => {
        setFileKartuPelajar({
            kartupelajar: ['','']
        })
        setIsDirty({
            ...isDirty,
            kartu: true
        });
        setPreviewKartuPelajar1("")
        setPreviewKartuPelajar2("")
    };

    //Bukti
    const submitBukti = (ev) => {
        ev.preventDefault();
        if (isSendingForm) return;
        const formData = new FormData();
        formData.append('image', fileBukti.bukti);
        setIsSendingForm(true);
        const source = axios.CancelToken.source();
        setSource(source);
        setProgress(0);
        Teams.post(`bukti/${Auth.data._id}`, formData, {
            headers : {
                'Content-Type' : 'multipart/form-data'
            },
            cancelToken: source.token,
            ...progressConfig
        })
        .then((res) => {
            setIsSendingForm(false);
            setInfo("Bukti berhasil disimpan di server.");
            setIsDirty({
                ...isDirty,
                bukti: false
            });
            updateClientData();
        }).catch((errors) => {
            setIsSendingForm(false);
            if(errors.response){
                setInfo("Terjadi kesalahan saat mengupload bukti. Silahkan coba lagi.");
            } else {
                setInfo("Timeout! Server not responding or check your connectivity.");
            }
        });
    };

    const buktiHandleChange = (files) => {
        if(files.length === 0){
            setInfo("File tidak valid! Periksa kembali batasan ukuran dan format file.");
            return;
        }
        let reader = new FileReader();
        reader.onloadend = () => {
            setFileBukti({
                bukti: files[0]
            });
            if(!isDirty.kartu)
                setIsDirty({
                    ...isDirty,
                    bukti: true
                });
            setPreviewBukti(reader.result);
        }
        reader.onerror = () => {
            setInfo("File tidak valid! Tidak dapat membaca file.");
        }
        reader.readAsDataURL(files[0]);
    };

    const removeBukti = () => {
        setFileBukti({bukti: ""});
        setPreviewBukti("");
        setIsDirty({
            ...isDirty,
            bukti: true
        });
    };
  
    const updateClientData = () => {
        Auth.data.members = body.members;
        Auth.data.phone = body.phone;
        Auth.data.idline = body.idline;
        Auth.data.kartu_pelajar = fileKartuPelajar.kartupelajar;
        Auth.data.bukti = fileBukti.bukti;
    }

    const [backdrop, setBackdrop] = useState(true);
    useEffect(() => {
        if(Auth.isAuthenticated){
            function readFile(data, callback ) {
                let reader = new FileReader();
                reader.onloadend = (ev) => {
                    callback(ev.target.result);
                }
                reader.onerror = () => {
                    setInfo("File tidak valid!");
                }
                reader.readAsDataURL(data);
            }

            setBody({
                members : Auth.data.members,
                phone : Auth.data.phone,
                idline : Auth.data.idline
            });
            setFileKartuPelajar({
                kartupelajar: Auth.data.kartu_pelajar
            });
            setFileBukti({
                bukti: Auth.data.bukti
            });

            for(let i = 0; i < 2; i++){
                if(Auth.data.kartu_pelajar[i] !== ''){
                    const kartu = ['', ''];
                    kartu[i] = Auth.data.kartu_pelajar[i];
                    kartu[(i+1)%2] = fileKartuPelajar.kartupelajar[(i+1)%2];
                    Teams.get(`/fotokartupelajar/${Auth.data._id}/${i}`, {responseType: 'blob'})
                    .then((res) => {
                        if(i === 0)
                            readFile(res.data, setPreviewKartuPelajar1);
                        else
                            readFile(res.data, setPreviewKartuPelajar2);
                    });
                }
            }
            if(Auth.data.bukti !== ''){
                Teams.get(`/buktipembayaran/${Auth.data._id}`, {responseType: 'blob'})
                .then((res) => {
                    readFile(res.data, setPreviewBukti);
                })
            }
            if(Auth.data.teamstatus === 'verified' || Auth.data.teamstatus === 'activated'){
                setBackdrop(false);
                setIsSendingForm(true);
            }
        }
    },[Auth.isInitialized])

    return (
        <UserDashboard>
            {Auth.isAuthenticated && (Auth.data.teamstatus === 'registered' || Auth.data.teamstatus === 'declined') &&
                <Typography variant="h4" color="error">
                    REGISTRASI TELAH DITUTUP
                </Typography>
            }
            {Auth.isAuthenticated && Auth.data.teamstatus === 'declined' &&
                <Typography variant="h5" color="error">
                    Data kamu gagal diverifikasi! <s>Silahkan periksa kembali data kamu.</s>
                </Typography>
            }
            <Prompt when={isDirty.data || isDirty.bukti || isDirty.kartu} message="Masih ada data yang belum disimpan. Ingin keluar dari halaman ini?"/>
            <Typography className={styles.title}>
                Data Tim
            </Typography>
            <Grid container>
                <Grid item xs={12} className={styles.container}>
                    <Title>Data Anggota</Title>
                        <Grid container spacing={1}>
                            <Grid item sm={6} xs={12} className={styles.inputBox}>
                                <Paper className={styles.paper}>
                                    <form className={styles.form} noValidate>
                                        <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <TextField
                                                required 
                                                fullWidth
                                                type= "text"
                                                value={body.members[0]}
                                                onChange={handleChange} 
                                                disabled={isSendingForm}
                                                name= "members"
                                                inputProps={{'data-number':0}}
                                                error={err.members[0] !== ''}
                                                helperText={err.members[0]}
                                                label="Nama Anggota 1"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={isSendingForm}
                                                required 
                                                fullWidth
                                                type= "text"
                                                value={body.phone[0]}
                                                onChange={handleChange} 
                                                name= "phone"
                                                inputProps={{'data-number':0}}
                                                error={err.phone[0] !== ''}
                                                helperText={err.phone[0]}
                                                label="No. Handphone"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={isSendingForm}
                                                fullWidth
                                                type= "text"
                                                value={body.idline[0]}
                                                onChange={handleChange} 
                                                name= "idline"
                                                inputProps={{'data-number':0}}
                                                error={err.idline[0] !== ''}
                                                helperText={err.idline[0]}
                                                label="ID Line (Opsional)"
                                            />
                                        </Grid>
                                    </Grid>
                                    </form>
                                </Paper>
                            </Grid>
                            <Grid item sm={6} xs={12} className={styles.inputBox}>
                                <Paper className={styles.paper}>
                                    <form className={styles.form} noValidate>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={isSendingForm}
                                                required 
                                                fullWidth
                                                type= "text"
                                                value={body.members[1]}
                                                onChange={handleChange} 
                                                name= "members"
                                                inputProps={{'data-number':1}}
                                                error={err.members[1] !== ''}
                                                helperText={err.members[1]}
                                                label="Nama Anggota 2"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={isSendingForm}
                                                required 
                                                fullWidth
                                                type= "text"
                                                value={body.phone[1]}
                                                onChange={handleChange} 
                                                name= "phone"
                                                inputProps={{'data-number':1}}
                                                error={err.phone[1] !== ''}
                                                helperText={err.phone[1]}
                                                label="No. Handphone"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={isSendingForm}
                                                fullWidth
                                                type= "text"
                                                value={body.idline[1]}
                                                onChange={handleChange} 
                                                name= "idline"
                                                inputProps={{'data-number':1}}
                                                error={err.idline[1] !== ''}
                                                helperText={err.idline[1]}
                                                label="ID Line (Opsional)"
                                            />
                                        </Grid>
                                    </Grid>
                                    </form>
                                </Paper>
                                {/* <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        className={styles.simpanButton}
                                        onClick={submitForm}
                                        disabled={isSendingForm || !isDirty.data}
                                    >
                                        Simpan Data
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </Grid>
                </Grid>   

                <Grid item xs={12}  className={styles.container} >
                    <Title>Kartu Pelajar</Title>
                    <Grid container direction='row' spacing={1}>
                        <Paper className={styles.paperKartu}>
                            <Grid item xs={11} className={styles.inputBox}>
                                <ImageUpload 
                                    name='kartupelajar1'
                                    label='Kartu Pelajar Anggota 1' 
                                    className={styles.kartuPelajar}
                                    src={previewKartuPelajar1}
                                    onDrop={kartuHandleChangeOne}
                                    disabled={isSendingForm}
                                />
                            </Grid>
                        </Paper>
                        <Paper className={styles.paperKartu}>
                            <Grid item xs={11} className={styles.inputBox}>
                                <ImageUpload 
                                    name='kartupelajar2'
                                    label='Kartu Pelajar Anggota 2'  
                                    className={styles.kartuPelajar}
                                    src={previewKartuPelajar2}
                                    onDrop={kartuHandleChangeTwo}
                                    disabled={isSendingForm}
                                />
                            </Grid>
                        </Paper>
                        {/* <Grid item xs={12}>
                            <Button
                                variant="contained"
                                className={classes.removeButton}
                                startIcon={<DeleteIcon />}
                                onClick={removeKartu}
                                disabled={isSendingForm || (fileKartuPelajar.kartupelajar[0] === '' && fileKartuPelajar.kartupelajar[1] === '')}
                            >
                            Remove
                            </Button>
                        </Grid> */}
                        {/* <Grid item xs={12}>
                            <Button 
                                className={styles.simpanButton}
                                onClick = {submitKartu}
                                disabled={isSendingForm || !isDirty.kartu}
                            >
                                Simpan Data
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} className={styles.container}>
                    <Title>Bukti Pembayaran</Title>
                    <h5>Biaya pendaftaran (Rp50.000,00) dapat dibayarkan ke salah satu rekening berikut:<br></br>
                    BCA 4361722773 a.n dina rakhmawati <br></br>
                    MANDIRI 1240010665579 a.n NAUROHA SHOFIYYAH</h5>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                        <Paper className={styles.paperBukti}>
                            <Grid item xs={11} className={styles.inputBox}>
                                <ImageUpload 
                                    name='bukti'
                                    label='Bukti Pembayaran Tim'  
                                    className={styles.kartuPelajar}
                                    onDrop={buktiHandleChange}
                                    src={previewBukti}
                                    disabled={isSendingForm}
                                    />
                            </Grid>
                        </Paper>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Button
                                variant="contained"
                                className={classes.removeButton}
                                startIcon={<DeleteIcon />}
                                onClick={removeBukti}
                                disabled={isSendingForm || fileBukti.bukti === ''}
                            >
                            Remove
                            </Button>
                        </Grid> */}
                        
                        {/* <Grid item xs={12}>
                            <Button 
                                className={styles.simpanButton}
                                onClick = {submitBukti}
                                disabled={isSendingForm || !isDirty.bukti}
                            >
                                Simpan Data
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                    {
                        Auth.isAuthenticated &&
                        (Auth.data.teamstatus === 'registered' || Auth.data.teamstatus === 'declined') &&
                        <Button 
                            variant="contained"
                            disabled={isSendingForm}
                            color="secondary" fullWidth
                            onClick={verifyDialog}
                        >
                            Verifikasi
                        </Button>
                    }
                </Grid>*/}
            </Grid>
            <Backdrop open={isSendingForm && backdrop} className={classes.backdrop}>
                <Grid container spacing={3} alignItems="center" justify="center" direction="column">
                    <Grid item>
                        <CircularProgress value={progress} variant="determinate" color="inherit" />
                    </Grid>
                    <Grid item>
                        <Typography>Sedang mengupload data... {Math.floor(progress)}%</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={cancelSubmit} variant="contained">Batal</Button>
                    </Grid>
                </Grid>
            </Backdrop>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={info !== ''}
                autoHideDuration={5000}
                onClose={handleClose}
                message={info}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
            <Dialog
                open={dialog.open}
                onClose={dialog.actionDefault}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialog.description}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={dialog.actionDefault} color="primary" autoFocus>
                    {dialog.defaultLabel}
                </Button>
                {dialog.actionSecondary &&
                    <Button onClick={dialog.actionSecondary} color="primary">
                        {dialog.secondaryLabel}
                    </Button>
                }
                </DialogActions>
            </Dialog>
        </UserDashboard>
    )
}
