import { Typography } from "@material-ui/core";
import React from "react";
import styles from "./Timeline.module.css";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

// Icons
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import EventIcon from "@material-ui/icons/Event";
import CreateIcon from "@material-ui/icons/Create";
import EmailIcon from "@material-ui/icons/Email";

export const Timeline = () => {
  return (
    <div className={styles.root}>
      <Typography className={styles.title} variant="h4">
        Timeline
      </Typography>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ background: "#faebe8", color: "#000000" }}
          contentArrowStyle={{ borderRight: "7px solid #faebe8" }}
          dateClassName={styles.date}
          date="16 April - 23 Mei 2021"
          iconStyle={{ background: "#A05969", color: "#fff" }}
          icon={<VpnKeyIcon />}
        >
          <h3 className="vertical-timeline-element-title">Pendaftaran</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          date="2 Mei 2021"
          contentStyle={{ background: "#faebe8", color: "#000000" }}
          contentArrowStyle={{ borderRight: "7px solid #faebe8" }}
          dateClassName={styles.date}
          iconStyle={{ background: "#A05969", color: "#fff" }}
          icon={<EventIcon />}
        >
          <h3 className="vertical-timeline-element-title">Main Pre-Event</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          date="30 Mei 2021"
          contentStyle={{ background: "#faebe8", color: "#000000" }}
          contentArrowStyle={{ borderRight: "7px solid #faebe8" }}
          dateClassName={styles.date}
          iconStyle={{ background: "#A05969", color: "#fff" }}
          icon={<CreateIcon />}
        >
          <h3 className="vertical-timeline-element-title">Babak Penyisihan</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          date="5 Juni 2021"
          contentStyle={{ background: "#faebe8", color: "#000000" }}
          contentArrowStyle={{ borderRight: "7px solid #faebe8" }}
          dateClassName={styles.date}
          iconStyle={{ background: "#A05969", color: "#fff" }}
          icon={<EmailIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Pengumuman Penyisihan
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          date="26 Juni 2021"
          contentStyle={{ background: "#faebe8", color: "#000000" }}
          contentArrowStyle={{ borderRight: "7px solid #faebe8" }}
          dateClassName={styles.date}
          iconStyle={{ background: "#A05969", color: "#fff" }}
          icon={<CreateIcon />}
        >
          <h3 className="vertical-timeline-element-title">Babak Semifinal</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          date="28 Juni 2021"
          contentStyle={{ background: "#faebe8", color: "#000000" }}
          contentArrowStyle={{ borderRight: "7px solid #faebe8" }}
          dateClassName={styles.date}
          iconStyle={{ background: "#A05969", color: "#fff" }}
          icon={<EmailIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Pengumuman Semifinal
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          date="3 Juli 2021"
          contentStyle={{ background: "#faebe8", color: "#000000" }}
          contentArrowStyle={{ borderRight: "7px solid #faebe8" }}
          dateClassName={styles.date}
          iconStyle={{ background: "#A05969", color: "#fff" }}
          icon={<CreateIcon />}
        >
          <h3 className="vertical-timeline-element-title">Babak Final</h3>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};
