import {Dashboard} from '../Dashboard';
import { dashboardAdminRoutes } from '../../../Routes/routes';

export const AdminDashboard = (props) => {
    return (
        <Dashboard
            listItem={dashboardAdminRoutes}>
                { props.children }
        </Dashboard>
    );
}
