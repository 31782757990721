import React, { useState } from 'react';
import { Grid, Snackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { LayoutForm, TextForm } from './Layout';
import { Teams } from '../../api';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { ROUTES } from "../../Routes/const";

export const Reset = () => {
  const { token } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [isSendingForm, setIsSendingForm] = useState(false);
  const initialState = {
    newPass: "",
    confirmPass: "",
    email: query.get("email"),
    token: token
  }
  const [body, setBody] = useState({...initialState});
  const [err, setErr] = useState({newPass: "", confirmPass: ""});
  const [snackMessage, setSnackMessage] = useState("");

  const handleClose = () => {
    setSnackMessage("");
    history.push(ROUTES.AUTH.LOGIN);
  }

  const handleChange = (ev)=>{
    if(err[ev.target.name] !== ''){
      setErr({
        ...err,
        [ev.target.name]: ''
      });
    }
    const value = ev.target.value;
    setBody({
      ...body,
      [ev.target.name]: value
    });
  }

  const submitForm = (ev) => {
    ev.preventDefault();
    if(isSendingForm) return;
    setErr({...initialState});
    setIsSendingForm(true);
    Teams.put('resetpassword', body)
    .then(res => {
      setSnackMessage("Password anda berhasil diubah! Silahkan login kembali dengan password baru anda.")
    }).catch((errors) => {
      setIsSendingForm(false);
      if(errors.response){
        if(errors.response.status === 400){
          const newErr = {
            ...err
          }
          for(let e in errors.response.data){
            newErr[e] = errors.response.data[e];
          }
          setErr(newErr);
        } else {
          setSnackMessage(errors.response.data.message);
        }
      } else {
        setSnackMessage("Timeout! Server not responding or check your connectivity.");
      }
    });
  }

  return (
    <LayoutForm
      formType="Reset"
      submitForm={submitForm}
      isSendingForm={isSendingForm}
      formButtonLabel="Reset Password"
    >
      <Grid item xs={12}>
        <TextForm
          required
          type="password"
          value={body.newPass}
          onChange={handleChange}
          name="newPass"
          error={err.newPass !== ''}
          helperText={err.newPass}
          label="Password Baru"
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <TextForm
          required
          type="password"
          value={body.confirmPass}
          onChange={handleChange}
          name="confirmPass"
          error={err.confirmPass !== ''}
          helperText={err.confirmPass}
          label="Konfirmasi Password Baru"
        />
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackMessage !== ''}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snackMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </LayoutForm>
  );
}