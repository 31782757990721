import React, {useEffect, useState} from 'react';
import { Typography, TextField, Grid } from '@material-ui/core';
import { Auth } from '../../../api';
import Title from '../Components/Title';
import UserDashboard from './UserDashboard';
import styles from './DataTim/DataTim.module.css';

export const Overview = () => {
    const [data, setData] = useState({
        id: '',
        name: '',
        school: '',
        email: ''
    });

    const [statusComponent, setStatusComponent] = useState(null);

    useEffect(() => {
        if(Auth.isAuthenticated){
            setData({
                id: Auth.data._id,
                name: Auth.data.name,
                school: Auth.data.school,
                email: Auth.data.email
            });
            if(Auth.data.teamstatus === "activated"){
                setStatusComponent(
                    <Typography variant="body1" color="primary">Data anda sudah diverifikasi.</Typography>
                )
            } else if(Auth.data.teamstatus === "verified"){
                setStatusComponent(
                    <Typography variant="body1" color="secondary">Data anda sedang diverifikasi. Mohon periksa dashboard/email sewaktu-waktu untuk melihat status anda.</Typography>
                )
            } else if(Auth.data.teamstatus === "declined"){
                setStatusComponent(
                    <Typography variant="body1" color="error" className={styles.reasonText}>Data anda bermasalah dengan alasan: {Auth.data.rejected_reason}.<br /><s>Silahkan ubah data anda di Data Tim.</s></Typography>
                )
            } else if(Auth.data.teamstatus === "registered"){
                setStatusComponent(
                    <Typography variant="body1" color="error"><s>Isi data anda di Data Tim.</s></Typography>
                )
            }
        }
    }, [Auth.isInitialized]);

    return (
        <UserDashboard>
            <Typography className={styles.title}>
                Info Dasar Tim
            </Typography>
            <Grid container>
                <Grid item xs={12} className={styles.container}>
                    <Title>Status Verifikasi</Title>
                    <Grid container spacing={1}>
                        { statusComponent }
                    </Grid>
                </Grid>
                <Grid item xs={12} className={styles.container}>
                    <Title>Overview Data</Title>
                    <form className={styles.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Kode Registrasi"
                                    fullWidth
                                    value={data.id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    helperText="Harap simpan dan rahasiakan kode registrasi ini."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nama Tim"
                                    fullWidth
                                    value={data.name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Asal Sekolah"
                                    fullWidth
                                    value={data.school}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <TextField
                                label="Email"
                                fullWidth
                                value={data.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </UserDashboard>
    );
}