import { useState, useEffect } from "react";
import { mainRoutes } from "../../Routes/routes";

import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Hidden,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouteLink } from "react-router-dom";

import Logo from "../../image/impact-navbar-logo.png";
import { Auth } from "../../api";
import { AccountCircle } from "@material-ui/icons";
import { ROUTES } from "../../Routes/const";

import styles from "./navbar.module.css";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    position: "relative",
    flexGrow: 1,
  },
  logo: {
    width: "auto",
    height: 48,
    padding: theme.spacing(1, 1, 1, 0),
  },
  menubutton: {
    margin: theme.spacing(0, 1),
  },
  homebutton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  loginbutton: {
    "&:hover": {
      backgroundColor: "#df2544",
    },
    backgroundColor: "#e33f5a",
    color: "#fff",
    margin: theme.spacing(0, 1),
  },
  leftSide: {
    display: "flex",
    justifyContent: "flex-start",
    flexShrink: 1,
  },
  leftSideMobile: {
    display: "flex",
    justifyContent: "flex-start",
    flexGrow: 1,
  },
  centerSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
    flexShrink: 1,
  },
  // properti untuk drawer
  drawerContainer: {
    padding: "20px 0px",
    width: "25vw",
    minWidth: "200px",
  },
  drawerLink: {
    textDecoration: "none",
    fontFamily: "FuturaMd",
    color: "#000",
    "&:hover": {
      textDecoration: "none",
      color: "#000",
    },
    "&:visited": {
      textDecoration: "none",
      color: "#000",
    },
    "&:active": {
      backgroundColor: "#fff",
      textDecoration: "none",
      color: "#000",
    },
  },
}));

function Navbar() {
  // style yang dipakai
  const classes = useStyles();

  // cek state untuk display apakah mobile atau desktop
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", setResponsiveness);
    return () => {
      window.removeEventListener("resize", setResponsiveness);
    };
  }, []);

  // navbar logo
  const navbarLogo = () => {
    return (
      <Button
        component={RouteLink}
        to="/"
        className={classes.homebutton}
        disableRipple
        disableFocusRipple
      >
        <img src={Logo} alt="Impact-2.0" className={classes.logo} />
        <Hidden mdDown>
          <Typography className={styles.logoTitle}>IMPACT 2.0</Typography>
        </Hidden>
      </Button>
    );
  };

  // navbar kalo di mobile
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "default",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={classes.drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <Box className={classes.leftSideMobile} display="flex">
          {navbarLogo()}
        </Box>

        <Box className={classes.rightSide}>
          <IconButton
            component={RouteLink}
            to={
              Auth.isAuthenticated
                ? ROUTES.DASHBOARD.PESERTA.OVERVIEW
                : ROUTES.AUTH.LOGIN
            }
            variant="contained"
          >
            <AccountCircle />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return mainRoutes.map((d) => {
      if (d.hide) return null;
      else
        return (
          <Link
            component={RouteLink}
            to={d.path}
            key={d.path}
            className={classes.drawerLink}
          >
            <MenuItem>{d.label}</MenuItem>
          </Link>
        );
    });
  };

  // navbar kalo di desktop
  const displayDesktop = () => {
    return (
      <Toolbar>
        <Box className={classes.leftSide} display="flex">
          {navbarLogo()}
        </Box>
        <Box className={classes.centerSide}>
          {mainRoutes.map((d) => {
            if (d.hide) return null;
            else
              return (
                <Button
                  component={RouteLink}
                  to={d.path}
                  key={d.path}
                  className={classes.drawerLink}
                >
                  {d.label}
                </Button>
              );
          })}
        </Box>
        <Box className={classes.rightSide}>
          <Button
            component={RouteLink}
            to={ROUTES.DASHBOARD.PESERTA.OVERVIEW}
            startIcon={<AccountCircle />}
            className={classes.loginbutton}
            variant="contained"
          >
            <Typography>
              {Auth.isAuthenticated ? Auth.data.name : "Akun"}
            </Typography>
          </Button>
        </Box>
      </Toolbar>
    );
  };

  // kode utama
  return (
    <AppBar className={classes.root} elevation={0}>
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
}

export default Navbar;
