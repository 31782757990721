import React, { useState, useEffect, useRef } from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { About } from "./About";
import { Timeline } from "./Timeline";
import Navbar from "../../../Components/Navbar/navbar";
import Footer from "../../../Components/Footer/footer";
import { ReactComponent as Decor } from "../../../image/decor-hexagon.svg";

import styles from "./Home.module.css";
import { Lomba } from "./Lomba";

import AOS from "aos";
import "aos/dist/aos.css";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(130deg,#f9d8d1, #a0596991), #f9d8d1",
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },
  container: {
    margin: theme.spacing(2, 4),
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  svg: {
    maxWidth: "100%",
    height: "auto",
  },
  span: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    margin: theme.spacing(2, 0),
  },
  spanSmall: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    margin: theme.spacing(0.5, 0),
  },
  spanWrap: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    flexWrap: "wrap",
    margin: theme.spacing(2, 0),
  },
  decor: {
    width: "100%",
    maxWidth: "400px",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    flexGrow: 1,
    margin: theme.spacing(8, 0),
  },
  guidebutton: {
    backgroundColor: "#f57270",
    "&:hover": {
      backgroundColor: "#f2514e",
    },
    color: "#fff",
  },
  // desktop styles
  left: {
    width: "50%",
    height: "100%",
    justifyContent: "flex-start",
    margin: theme.spacing(2, 0),
    padding: theme.spacing(7.5, 10),
    alignContent: "center",
    flexGrow: 1,
  },
  leftcontent: {
    margin: theme.spacing(2, 0),
  },
  right: {
    width: "50%",
    height: "100%",
    maxWidth: "600px",
    justifyContent: "flex-end",
    margin: theme.spacing(1, 1),
    flexShrink: 1,
  },
  imgdesktop: {
    margin: theme.spacing(2, 5),
  },
}));

export const Home = () => {
  const classes = useStyles();

  const calculateTimeLeft = () => {
    let difference = +new Date(`2021-06-28T15:00:00.000+07:00`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hari: Math.floor(difference / (1000 * 60 * 60 * 24)),
        jam: Math.floor((difference / (1000 * 60 * 60)) % 24),
        menit: Math.floor((difference / 1000 / 60) % 60),
        detik: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;

  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer=setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  const about = useRef(null);
  const timeline = useRef(null);
  const lomba = useRef(null);
 
  const executeScrollAbout = () => about.current.scrollIntoView();
  const executeScrollTimeline = () => timeline.current.scrollIntoView();
  const executeScrollLomba = () => lomba.current.scrollIntoView();

  // cek state untuk display apakah mobile atau desktop
  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    AOS.init({ duration: 1500, once: true });
  }, []);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  // heading
  const Heading = () => {
    return (
      <Typography
        className={styles.heading}
        variant={window.innerWidth < 900 ? "h4" : "h2"}
      >
        IMPACT 2.0
      </Typography>
    );
  };

  const Slogan = () => {
    return (
      <Typography
        className={styles.heading}
        variant={window.innerWidth < 900 ? "h6" : "h4"}
      >
        Act to Make an IMPACT
      </Typography>
    );
  };

  const Schedule = () => {
    return (
      <Typography
        className={styles.text}
        variant={window.innerWidth < 900 ? "p" : "p"}
      >
        Final : 3 Juli 2021
      </Typography>
    );
  };

  const SisaWaktu = () => {
    return (
      <Typography
        className={styles.text}
        variant={window.innerWidth < 900 ? "h6" : "h5"}
      >
        PENGUMUMAN SEMIFINAL
      </Typography>
    );
  };

  // tombol untuk lihat guide
  const GuideButton = () => {
    return (
      <Button
        href="GUIDEBOOK IMPACT 2.0.pdf"
        download
        variant="contained"
        target="_blank"
        className={classes.guidebutton}
      >
        Download Guidebook
      </Button>
    );
  };

  const PengumumanButton = () => {
    return (
      <Button
        href="/pengumuman"
        variant="contained"
        target="_blank"
        className={classes.guidebutton}
      >
        Cek Hasil Semifinal
      </Button>
    );
  };

  const Timer = () => {
    return (
      <Typography
        className={styles.text}
        variant={window.innerWidth < 900 ? "h6" : "h5"}
      >
        {timerComponents.length ? timerComponents : <span><PengumumanButton /></span>}
      </Typography>
    );
  };

  const ScrollAbout = () => {
    return (
      <Button
        className={classes.guidebutton}
        onClick={executeScrollAbout}
        variant="contained"
      >
        Apa itu IMPACT?
      </Button>
    );
  };

  const ScrollTimeline = () => {
    return (
      <Button
        className={classes.guidebutton}
        onClick={executeScrollTimeline}
        variant="contained"
      >
        Timeline Lomba
      </Button>
    );
  };

  const ScrollLomba = () => {
    return (
      <Button
        className={classes.guidebutton}
        onClick={executeScrollLomba}
        variant="contained"
      >
        Mata Lomba
      </Button>
    );
  };

  const ScrollImageDesktop = () => {
    return (
      <img src="scroll.gif" alt="scroll down" className={styles.scrolldesktop}></img>
    );
  };
  
  const ScrollImageMobile = () => {
    return (
      <img src="scroll.gif" alt="scroll down" className={styles.scrollmobile}></img>
    );
  };

  // display
  const displayMobile = () => {
    return (
      <div className={classes.container}>
        <div className={classes.span}>
          <Heading style={{ textAlign: "center" }} />
        </div>
        <div className={classes.span}>
          <Slogan style={{ textAlign: "center" }} />
        </div>
        <div className={classes.span}>
          <GuideButton />
        </div>
        <div className={classes.span}>
          <Schedule style={{ textAlign: "center" }} />
        </div>
        <div className={classes.spanSmall}>
          <SisaWaktu style={{ textAlign: "center" }} />
        </div>
        <div className={classes.spanSmall}>
          <Timer style={{ textAlign: "center" }} />
        </div>
        <div className={classes.decor}>
          <Decor className={classes.svg} />
        </div>
        <ScrollImageMobile />
      </div>
    );
  };
  const displayDesktop = () => {
    return (
      <div className={classes.container}>
        <div className={classes.span}>
          <div className={classes.left}>
            <div className={classes.leftcontent}>
              <ScrollAbout /> <ScrollTimeline /> <ScrollLomba />
            </div>
            <div className={classes.leftcontent}>
              <Heading />
            </div>
            <div className={classes.leftcontent}>
              <Slogan />
            </div>
            <div className={classes.leftcontent}>
              <GuideButton />
            </div>
            <div className={classes.leftcontent}>
              <Schedule />
            </div>
            <div className={classes.leftcontent}>
              <SisaWaktu />
              <Timer />
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.imgdesktop}>
              <Decor className={classes.svg} />
            </div>
          </div>
        </div>
        <ScrollImageDesktop />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Navbar style={{ flexShrink: 0 }} />
      {mobileView ? displayMobile() : displayDesktop()}
      <div ref={about} className={styles.animation}>
        <About />
      </div>
      <div ref={timeline} className={styles.animation}>
        <Timeline />
      </div>
      <div ref={lomba} className={styles.animation}>
        <Lomba />
      </div>
      <Footer style={{ flexShrink: 0 }} />
    </div>
  );
};