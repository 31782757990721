import { StylesProvider } from "@material-ui/styles";
import React from "react";
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import AppRoute from "./Routes/AppRoute";
import { mainRoutes, authRoutes, dashboardRoutes, dashboardAdminRoutes, dashboardAdminQueryRoutes } from "./Routes/routes";

import './App.css';

function Routes({routes, restrict, redirect, show}) {
  return (
    <Switch>
      {routes.map((route) => {
        if (!route.ignore){
          return (
          <AppRoute
            exact={route.exact || true}
            path={route.path}
            key={route.path}
            restrict={restrict}
            show={route.show || show}
            redirect={route.redirect || redirect}
            component={route.component}
            customProps={route.customProps}
          />);
        }
        return null;
      })}
    </Switch>
  );
}

export const App = () => {
  return (
    <StylesProvider injectFirst>
      <Router>
        <Routes
          routes={mainRoutes}
        />
          <Routes
            restrict={true}
            redirect='auth'
            routes={authRoutes}
            show='authenticated'
          />
          <Routes
            restrict={true}
            redirect='dashboard'
            routes={dashboardRoutes}
            show='verified'
          />
          <Routes
            restrict={true}
            redirect='admin'
            routes={dashboardAdminRoutes}
            show='admin'
          />
          <Routes
            restrict={true}
            redirect='admin'
            routes={dashboardAdminQueryRoutes}
            show='admin'
          />
      </Router>
    </StylesProvider>
  );
}

export default App;
