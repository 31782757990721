import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import styles from './webinar.module.css';
import PropTypes from "prop-types";

import Navbar from '../../../Components/Navbar/navbar';
import Footer from '../../../Components/Footer/footer';
import Webinar1 from "../../../image/webinar.png";

// styles
const useStyles = makeStyles((theme) => ({
    root: {
        background: "#FAEBE8",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    container: {
        margin: theme.spacing(2, 4),
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    guidebutton: {
        backgroundColor: "#f57270",
        "&:hover": {
          backgroundColor: "#f2514e",
        },
        color: "#fff",
      },
    heading: {
        color: "#A05969",
        fontFamily: "Azonix",
    },
    svg: {
        maxWidth: "100%",
        height: "auto",
    },
    span: {
        width: "100%",
        height: "auto",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        margin: theme.spacing(2,0),
    }
}))

const YoutubeEmbed = ({ embedId }) => (
    <div className={styles.video}>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
  
  YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
  };
  
  export default YoutubeEmbed;

export const Webinar = () => {
    const classes = useStyles();

    // cek state untuk display apakah mobile atau desktop
    const [state, setState] = useState({
        mobileView: false,
    });

    const { mobileView } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
            ? setState((prevState) => ({ ...prevState, mobileView: true }))
            : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);
    
    // display
    const displayMobile = () => {
        return(
            <div className={styles.root}>
                <div className={styles.container}>
                <h2>Webinar</h2>
                    <div className="App">
                        <YoutubeEmbed embedId="Zh2un_JRNts" />
                    </div>
                    <div className={classes.span}>
                        <img src={Webinar1} alt="webinar impact" className={classes.svg}/>
                    </div>
                </div>
            </div>
            )
        }
    const displayDesktop = () => {
        return (
            <div className={styles.root}>
                <div className={styles.container}>
                <h2>Webinar</h2>
                    <div className="App">
                        <YoutubeEmbed embedId="Zh2un_JRNts" />
                    </div>
                    <div className={classes.span}>
                        <img src={Webinar1} alt="webinar impact" className={classes.svg} style={{paddingTop: "20px"}}/>
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <div className={classes.root}>
            <Navbar style={{flexShrink: 0}} />
            {mobileView ? displayMobile() : displayDesktop()}
            <Footer style={{flexShrink: 0}} />
        </div>
    )
};