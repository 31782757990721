import { Paper, Typography, Grid, Button, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styles from './DataTim/DataTim.module.css';
import Title from '../Components/Title';
import { Teams, Auth } from '../../../api';
import UserDashboard from './UserDashboard';

const useStyles = makeStyles((theme) => ({
    guidebutton: {
        backgroundColor: "#f57270",
        "&:hover": {
            backgroundColor: "#f2514e"
        },
        color: "#fff",
        width: "100%"
    }
}))

export const Sertifikat = () => {
    const classes = useStyles();

    const [data, setData] = useState({
        members: ['', ''],
        cert0: '',
        cert1: ''
    });

    function readFile(f, key, callback) {
        let reader = new FileReader();
        reader.onloadend = (ev) => {
            setData(p => ({
                ...p,
                [key]: ev.target.result
            }));
            callback(ev.target.result);
        }
        reader.onerror = () => {
            alert("File tidak valid!");
        }
        reader.readAsDataURL(f);
    }

    function downloadLink(num,url){
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Sertifikat Penyisihan IMPACT - ${data.members[num]}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const DownloadCert0 = async () => {
        if(data.cert0 === ''){
            const result = await Teams.get(`/cert/${Auth.data._id}/0`, {responseType: 'blob'});
            return readFile(result.data, "cert0", (res) => {
                downloadLink(0, res);
            });
        }
        downloadLink(0, data.cert0);
    }

    const DownloadCert1 = async () => {
        if(data.cert1 === ''){
            const result = await Teams.get(`/cert/${Auth.data._id}/1`, {responseType: 'blob'});
            return readFile(result.data, "cert1", (res) => {
                downloadLink(1, res);
            });
        }
        downloadLink(1, data.cert1);
    }

    useEffect(() => {
        if(Auth.isAuthenticated){
            setData({
                ...data,
                members: Auth.data.members
            })/*
            
            for(let i = 0; i < 2; i++){
                Teams.get(`/cert/${Auth.data._id}/${i}`, {responseType: 'blob'})
                .then((res) => {
                    readFile(res.data, `cert${i}`);
                });
            }*/
        }
    },[Auth.isInitialized])

    return (
        <UserDashboard>
            <Typography className={styles.title}>
                Sertifikat
            </Typography>
            <Grid container>
                <Grid item xs={12}  className={styles.container} >
                    <Title>Penyisihan</Title>
                    <Grid container direction='row' spacing={1}>
                        <Paper className={styles.paperKartu}>
                            <Grid item xs={11} className={styles.inputBox}>
                                <Button
                                    onClick={DownloadCert0}
                                    className={classes.guidebutton}
                                >Sertifikat Penyisihan {data.members[0]}</Button>
                            </Grid>
                        </Paper>
                        <Paper className={styles.paperKartu}>
                            <Grid item xs={11} className={styles.inputBox}>
                                <Button
                                    onClick={DownloadCert1}
                                    className={classes.guidebutton}
                                >Sertifikat Penyisihan {data.members[1]}</Button>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </UserDashboard>
    )
}
