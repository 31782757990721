import React, { useState } from 'react';
import { Grid, Link, Typography, Box } from '@material-ui/core';
import { useHistory, Link as RouteLink } from 'react-router-dom';
import { Auth, Teams } from '../../api';
import { LayoutForm, TextForm } from './Layout';
import { ROUTES } from "../../Routes/const";

export const Register = () => {
  const history = useHistory();
  const [isSendingForm, setIsSendingForm] = useState(false);
  const initialState = {
    name: '',
    school: '',
    email: '',
    password: '',
    confirm: ''
  }
  const [body, setBody] = useState({...initialState});
  const [err, setErr] = useState({...initialState});

  const handleChange = (ev)=>{
    if(err[ev.target.name] !== ''){
      setErr({
        ...err,
        [ev.target.name]: ''
      });
    }
    const value = ev.target.value;
    setBody({
      ...body,
      [ev.target.name]: value
    });
  }

  const submitForm = (ev) => {
    ev.preventDefault();
    if(isSendingForm) return;
    setErr({...initialState});
    setIsSendingForm(true);
    Teams.post('register', body)
    .then(res => {
      setIsSendingForm(false);
      Auth.load()
      .then(() => {
        history.push(ROUTES.AUTH.VERIFY_RESEND);
      });
    }).catch((errors) => {
      setIsSendingForm(false);
      if(errors.response){
        const newErr = {
          ...err
        }
        for(let e in errors.response.data){
          newErr[e] = errors.response.data[e];
        }
        setErr(newErr);
      } else {
        setErr({confirm: "Timeout! Server not responding or check your connectivity."});
      }
    });
  }

  return (
    <LayoutForm
      formType="Register"
      submitForm={submitForm}
      isSendingForm={isSendingForm}
      formButtonLabel="Register"
      bottom={
        <>
        <Grid container justify="center">
          <Grid item>
            <Link component={RouteLink} to={ROUTES.AUTH.LOGIN} variant="body2">
              Sudah punya akun? Masuk
            </Link>
          </Grid>
        </Grid>
        
        <Grid container justify="center">
          <Grid item>
            <Link component={RouteLink} to={ROUTES.AUTH.FORGOT} variant="body2">
              Lupa password?
            </Link>
          </Grid>
        </Grid>
        </>
      }
    >
      <Grid item xs={12}>
        <Typography variant="h4" color="error" align="center">
          <Box fontWeight="fontWeightBold" m={0}>
            PENDAFTARAN SUDAH DITUTUP
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextForm
          required
          value={body.name}
          onChange={handleChange}
          name="name"
          error={err.name !== ''}
          helperText={err.name}
          label="Nama Tim"
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <TextForm
          required
          value={body.school}
          onChange={handleChange}
          name="school"
          error={err.school !== ''}
          helperText={err.school}
          label="Asal Sekolah"
        />
      </Grid>
      <Grid item xs={12}>
        <TextForm
          required
          type="email"
          value={body.email}
          onChange={handleChange}
          name="email"
          error={err.email !== ''}
          helperText={err.email}
          label="Email"
        />
      </Grid>
      <Grid item xs={12}>
        <TextForm
          required
          type="password"
          value={body.password}
          onChange={handleChange}
          name="password"
          error={err.password !== ''}
          helperText={err.password}
          label="Password"
        />
      </Grid>
      <Grid item xs={12}>
        <TextForm
          required
          type="password"
          value={body.confirm}
          onChange={handleChange}
          name="confirm"
          error={err.password !== '' || err.confirm !== ''}
          helperText={err.confirm}
          label="Confirm Password"
        />
      </Grid>
    </LayoutForm>
  );
}