import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import styles from "./About.module.css";

export const About = () => {
  return (
    <div className={styles.root}>
      <Typography className={styles.title} variant="h4">
        Apa itu IMPACT?
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={styles.paper}>
            <Typography className={styles.content}>
              IMPACT 2.0 adalah acara yang diselenggarakan secara daring oleh
              Sekolah Teknik Elektro dan Informatika (STEI) ITB 2020 dibawah
              Himpunan Mahasiswa Informatika (HMIF) ITB. <br />
              <br />
              Pada tahun ini, IMPACT 2.0 mengusung tema “Act to Make an Impact”
              yang dilatarbelakangi pengabdian masyarakat oleh massa STEI 2020
              dengan memberikan kesempatan kepada siswa kelas 10 dan kelas 11
              SMA/sederajat se-Indonesia berkompetisi di bidang akademis, yaitu
              Matematika, Fisika, dan Komputer. Kompetisi diadakan dalam bentuk
              tim yang beranggotakan 2 orang siswa dari SMA yang sama. <br />
              <br />
              Adapun rangkaian acara IMPACT 2.0 dimulai dengan sebuah Talkshow
              Webinar yang bertemakan peralihan masa SMA menuju kuliah agar
              nantinya siswa SMA/sederajat dapat mempersiapkan dirinya di dunia
              perkuliahan.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
