import { Home } from "../Pages/Main/Home/Home";
import { Poster } from "../Pages/Main/Poster/Poster";
import { Petunjuk } from "../Pages/Main/Petunjuk/Petunjuk";
import { Faq } from "../Pages/Main/Faq/Faq";
import { Merch } from "../Pages/Main/Merch/Merch";
import { Webinar } from "../Pages/Main/Webinar/Webinar";
import { Pengumuman } from "../Pages/Main/Pengumuman/Pengumuman";
import { Semifinal } from "../Pages/Main/Semifinal/Semifinal";
import { Login } from "../Pages/Auth/Login";
import { Register } from "../Pages/Auth/Register";
import { Forgot } from "../Pages/Auth/Forgot";
import { Reset } from "../Pages/Auth/Reset";
import { VerifyResend } from "../Pages/Auth/VerifyResend";
import { Overview } from "../Pages/Dashboard/Peserta/Overview";
import { SemifinalPeserta } from "../Pages/Dashboard/Peserta/Semifinal";
import { SemifinalAdmin } from "../Pages/Dashboard/Admin/Semifinal";
import { Sertifikat } from "../Pages/Dashboard/Peserta/Sertifikat";
import { DataTim } from "../Pages/Dashboard/Peserta/DataTim/DataTim";
import { TabelSemua } from "../Pages/Dashboard/Admin/TabelSemua/TabelSemua";
import { VerifTim } from "../Pages/Dashboard/Admin/VerifTim/VerifTim";
import { DataVerif } from "../Pages/Dashboard/Admin/VerifTim/DataVerif/DataVerif";
import { ActiveTim } from "../Pages/Dashboard/Admin/ActiveTim/ActiveTim";
import { DataActive } from "../Pages/Dashboard/Admin/ActiveTim/DataActive/DataActive";
import { SemifinalTim } from "../Pages/Dashboard/Admin/SemifinalTim/SemifinalTim";
import { VerifyComponent } from "../api";
import {
  Dashboard,
  People,
  Home as HomeIcon,
  Settings,
  AssignmentLate,
  CheckCircle,
  Assignment,
  AssignmentInd,
  VerifiedUser
} from "@material-ui/icons";
import { ROUTES } from "./const";

export const mainRoutes = [
  {
    label: "Homepage",
    path: ROUTES.HOMEPAGE.MAIN,
    exact: true,
    hide: true,
    component: Home,
  },
  {
    label: "Poster",
    path: ROUTES.HOMEPAGE.POSTER,
    component: Poster,
  },
  {
    label: "Petunjuk Pendaftaran",
    path: ROUTES.HOMEPAGE.PETUNJUK,
    component: Petunjuk,
  },
  {
    label: "FAQ",
    path: ROUTES.HOMEPAGE.FAQ,
    component: Faq,
  },
  {
    label: "Merch",
    path: ROUTES.HOMEPAGE.MERCH,
    component: Merch,
  },
  {
    label: "Webinar",
    path: ROUTES.HOMEPAGE.WEBINAR,
    component: Webinar,
  },
  {
    label: "Pengumuman",
    path: ROUTES.HOMEPAGE.PENGUMUMAN,
    component: Pengumuman,
  },
  {
    label: "Semifinal",
    path: ROUTES.HOMEPAGE.SEMIFINAL,
    component: Semifinal,
  }
];

const AUTH_URL = ROUTES.AUTH;
// ENDPOINT /sso/:authroute
export const authRoutes = [
  {
    label: "Login",
    path: AUTH_URL.LOGIN,
    exact: true,
    component: Login,
  },
  {
    label: "Register",
    path: AUTH_URL.REGISTER,
    exact: true,
    component: Register,
  },
  {
    label: "Forgot Password",
    path: AUTH_URL.FORGOT,
    exact: true,
    component: Forgot,
  },
  {
    label: "Reset Password",
    path: AUTH_URL.RESET,
    exact: true,
    component: Reset,
  },
  {
    label: "Resend Email",
    path: AUTH_URL.VERIFY_RESEND,
    show: "emailverify",
    redirect: "emailverify",
    exact: true,
    component: VerifyResend,
  },
  {
    label: "Verify Email Token",
    path: AUTH_URL.VERIFY,
    show: "emailverify",
    redirect: "emailverify",
    component: VerifyComponent,
  },
];

const DASHBOARD_URL = ROUTES.DASHBOARD;
// ENDPOINT /dashboard/:dashboardRoutes
export const dashboardRoutes = [
  {
    label: "Homepage",
    path: ROUTES.HOMEPAGE.MAIN,
    ignore: true,
    exact: true,
    icon: HomeIcon,
  },
  {
    label: "Overview",
    path: DASHBOARD_URL.PESERTA.OVERVIEW,
    exact: true,
    component: Overview,
    icon: Dashboard,
  },
  {
    label: "Data Tim",
    path: DASHBOARD_URL.PESERTA.DATA_TIM,
    exact: true,
    component: DataTim,
    icon: People,
  },
  {
    label: "Sertifikat",
    path: DASHBOARD_URL.PESERTA.SERTIFIKAT,
    exact: true,
    component: Sertifikat,
    isActivated: true,
    icon: AssignmentInd,
  },
  {
    label: "Semifinal",
    path: DASHBOARD_URL.PESERTA.SEMIFINAL_PESERTA,
    exact: true,
    component: SemifinalPeserta,
    icon: Assignment,
  },
  {
    label: "Admin",
    path: DASHBOARD_URL.ADMIN.ALL_TEAMS,
    ignore: true,
    exact: false,
    isAdmin: true,
    icon: Settings,
  },
];

// ENDPOINT /dashboard/admin/:dashboardAdminRoutes
export const dashboardAdminRoutes = [
  {
    label: "Dashboard",
    path: DASHBOARD_URL.PESERTA.OVERVIEW,
    exact: true,
    ignore: true,
    icon: Dashboard,
  },
  {
    label: "All Teams",
    path: DASHBOARD_URL.ADMIN.ALL_TEAMS,
    exact: true,
    component: TabelSemua,
    icon: People,
  },
  {
    label: "Verify Teams",
    path: DASHBOARD_URL.ADMIN.VERIFY_TEAMS,
    exact: false,
    component: VerifTim,
    icon: AssignmentLate,
  },
  {
    label: "Active Teams",
    path: DASHBOARD_URL.ADMIN.ACTIVE_TEAMS,
    exact: false,
    component: ActiveTim,
    icon: CheckCircle,
  },
  {
    label: "Semifinal Teams",
    path: DASHBOARD_URL.ADMIN.SEMIFINAL_TEAMS,
    exact: true,
    component: SemifinalTim,
    icon: VerifiedUser,
  },
  {
    label: "Tambah Soal Semifinal",
    path: DASHBOARD_URL.ADMIN.SEMIFINAL_ADMIN,
    exact: true,
    component: SemifinalAdmin,
    icon: Assignment,
  }
];

export const dashboardAdminQueryRoutes = [
  {
    label: "Verify Teams Query",
    path: DASHBOARD_URL.ADMIN.VERIFY_TEAMS_QUERY,
    component: DataVerif,
  },
  {
    label: "Active Teams Query",
    path: DASHBOARD_URL.ADMIN.ACTIVE_TEAMS_QUERY,
    component: DataActive,
  },
];
