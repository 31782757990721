import React from 'react';
import Navbar from '../../../Components/Navbar/navbar';
import Footer from '../../../Components/Footer/footer';
import styles from './petunjuk.module.css';
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
    <div className={styles.video}>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
  
  YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
  };
  
  export default YoutubeEmbed;

export const Petunjuk = () => {
    return (
        <div className={styles.root}>
            <div className={styles.bar}>
                <Navbar />
            </div>
            
            <div className={styles.container}>
            <h2>Instructions</h2>

              <div className="App">
                <YoutubeEmbed embedId="hMv2I7FYICY" />
              </div>

            <h3>Petunjuk Registrasi</h3>
            
            <p>Peserta membuat akun pada website <a href="/">www.impactitb.com</a>. Dalam proses pembuatan akun, peserta diwajibkan mengisi:
            <ol><li>Nama tim</li>
            <li>Asal sekolah</li>
            <li>Email (salah satu peserta)</li>
            <li>Password</li></ol></p>
            <p><b>Satu tim hanya perlu membuat satu akun</b> (membuat akun per tim, <b>bukan</b> per orang)</p>
            
            <p>Peserta lalu akan diminta melakukan verifikasi akun melalui email yang dimasukkan peserta saat registrasi</p>
            
            <p>Setelah akun terverifikasi, setiap tim harus mengisi data tim dan kemudian memverifikasinya. Data tim mencakup:
            <ol><li>Nama kedua anggota</li>
            <li>Nomor <i>handphone</i> kedua anggota</li>
            <li>ID Line kedua anggota (optional)</li>
            <li>Kartu pelajar kedua anggota</li>
            <li>Bukti pembayaran</li>
            Biaya pendaftaran (Rp50.000,00) dapat dibayarkan ke salah satu rekening berikut:<br></br>
            BCA 4361722773 a.n dina rakhmawati<br></br>
            MANDIRI 1240010665579 a.n NAUROHA SHOFIYYAH</ol></p>
            
            <p>Data tim dapat disimpan dan diubah kapan saja. Akan tetapi, jika data tersebut telah diverifikasi, maka data tidak dapat diubah lagi.</p>
            
            <p>Batas akhir untuk verifikasi data adalah pada 23 Mei 2021 pukul 23:59</p>

            <h3>Petunjuk Pelaksanaan</h3>
            
            <p><b>Penyisihan</b>
            <ol><li>Babak penyisihan akan dilakukan secara daring melalui website IMPACT (<a href="/">www.impactitb.com</a>) pada tanggal 30 Mei 2021.</li>
            <li>Soal penyisihan terdiri atas tiga bidang, yaitu matematika, fisika, dan informatika, dengan setiap mata pelajaran terdiri atas 20 soal isian singkat.</li>
            <li>Peserta diperkenankan menggunakan kalkulator.</li>
            <li>Kedua anggota tim mengerjakan soal pada waktu yang sama, dengan platform pengawasan yang akan diberikan nanti.</li>
            <li>20 tim dengan poin tertinggi akan maju ke semifinal, dan akan diumumkan pada tanggal 5 Juni 2021.</li></ol></p>
            
            <p><b>Semifinal</b>
            <ol><li>Babak semifinal akan dilaksanakan secara daring melalui platform meeting pada tanggal 26 Juni 2021.</li>
            <li>Sistem lomba pada babak semifinal akan berbentuk seperti <i>post-to-post</i>, dengan setiap pos memiliki bidang, tingkat kesulitan, serta reward yang berbeda-beda.</li>
            <li>Soal-soal yang diujikan pada babak semifinal terdiri atas tiga bidang, yaitu matematika, fisika, dan informatika.</li>
            <li>Detail dan teknis lebih lanjut mengenai babak semifinal akan diberitahukan lebih lanjut setelah pengumuman semifinalis.</li>
            <li>5 tim dengan poin tertinggi akan maju ke babak final, dan akan diumumkan pada tanggal 28 Juni 2021.</li></ol></p>
            
            <p><b>Final</b>
            <ol><li>Babak final akan dilaksanakan secara daring melalui platform meeting pada tanggal 3 Juli 2021.</li>
            <li>Sistem lomba pada babak final terdiri dari pengerjaan soal problem-solving dan presentasi jawaban.</li>
            <li>Soal-soal yang diujikan pada babak final terdiri atas tiga bidang, yaitu matematika, fisika, dan informatika.</li>
            <li>Detail dan teknis lebih lanjut mengenai babak final akan diberitahukan lebih lanjut setelah pengumuman finalis.</li>
            <li>Peserta dengan tiga poin tertinggi akan masing masing mendapatkan juara 1,2, dan 3 secara berurutan.</li></ol></p>

            <p><b>Silabus</b>
            <ol><li>Matematika</li>
            <ul><li>Aljabar</li>
            <li>Kombinatorika</li>
            <li>Geometri</li>
            <li>Teori Bilangan</li></ul>
            <li>Fisika</li>
            <ul><li>Kinematika, Dinamika, dan Energi</li>
            <li>Fluida, Gelombang, dan Osilasi</li>
            <li>Gas dan Termodinamika</li>
            <li>Listrik dan Magnet</li></ul>
            <li>Komputer</li>
            <ul><li>Teori Bilangan</li>
            <li>Kombinatorika</li>
            <li>Logika</li>
            <li>Pseudocode</li></ul></ol></p>
            
            </div>
            <div className={styles.bar}>
                <Footer />
            </div>
        </div>
    )
}
