import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '35px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#A05969',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#A05969',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  fontWeight: 'bold',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const ImageUpload = ({name, label, src, onDrop, ...rest}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({disabled: rest.disabled, accept: ['.jpg', '.jpeg', '.png'], onDrop: onDrop, maxSize: 2000000, maxFiles: 1});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container" key={name}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} name={name} {...rest} />
        <p>{label}</p>
        {isDragActive ? 
          (isDragAccept ? <p>Drop file di sini.</p> : <p>Format file tidak didukung!</p>):<p>Drag file (atau klik di sini).</p>
        }
        <span><small>Format: .jpeg/.jpg/.png. Max: 2 MBytes</small></span>
            <div>
              {
                src !== '' && 
                <img src={src} style={{width: '100%', height:'100%', border:'3px solid #CCC'}} alt='preview' />
              }
            </div>
      </div>
    </div>
  );
}

export default ImageUpload;