import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, AppBar, Toolbar, List, Typography, Divider, Grid,
        IconButton, Badge, Container, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExitToApp as ExitToAppIcon, Menu as MenuIcon} from '@material-ui/icons';
import { Link as RouterLink } from "react-router-dom";
import { Auth, LogoutComponent } from '../../api';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#FAEBE8',
  },
  menuButton: {
    marginRight: 36,
    color: '#A05969',
  },
  logoutButton: {
    color: '#A05969',
  },
  title: {
    flexGrow: 1,
    color: '#A05969',
    fontWeight: 'bolder',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export const Dashboard = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
          { props.listItem.map((d) => {
            if(Auth.data){
              if((d.isAdmin && !Auth.data.isAdmin) || (d.isActivated && !Auth.isActivated()))
                return null;
            }
            return (
              <ListItem key={d.path} button component={RouterLink} to={d.path}>
                <ListItemIcon>
                  <d.icon />
                </ListItemIcon>
                <ListItemText primary={d.label} />
              </ListItem>
            );
          }) }
        </List>
      <Divider />
    </div>
  );

  return (
    <div>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)} className={classes.menuButton}><MenuIcon /></IconButton>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
        Dashboard
      </Typography>
      <LogoutComponent>
            <IconButton color="inherit">
                <Badge className={classes.logoutButton}>
                  <ExitToAppIcon />
                </Badge>
            </IconButton>
      </LogoutComponent>
      </Toolbar>
      </AppBar>
      
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              { props.children }
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}