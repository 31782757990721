import React from "react";
import Navbar from "../../../Components/Navbar/navbar";
import Footer from "../../../Components/Footer/footer";
import styles from "./faq.module.css";

export const Faq = () => {
  return (
    <div className={styles.root}>
      <div className={styles.bar}>
        <Navbar />
      </div>

      <div className={styles.container}>
        <h2 className={styles.subTitle}>Frequently Asked Questions</h2>
        <p className={styles.content}>
          1. Apa itu IMPACT 2.0?<br></br>IMPACT 2.0 (STEI Mathematics, Physics,
          and Informatics Competition) adalah kompetisi yang diadakan oleh
          Sekolah Teknik Elektro dan Informatika ITB 2020.
        </p>

        <p className={styles.content}>
          2. Bidang apa saja yang dilombakan?<br></br>Matematika, fisika, dan
          informatika
        </p>

        <p className={styles.content}>
          3. Siapa saja yang boleh mengikuti lomba IMPACT 2.0?<br></br>Siswa SMA
          kelas 10 dan 11 yang membentuk satu tim
        </p>

        <p className={styles.content}>
          4. Satu tim terdiri atas berapa siswa?<br></br>Satu tim terdiri atas
          dua orang siswa yang berasal dari SMA yang sama
        </p>

        <p className={styles.content}>
          5. Apa saja rangkaian acara IMPACT 2.0?<br></br>IMPACT 2.0 terdiri
          atas dua rangkaian acara yakni pre-event dan main event yang ditutup
          dengan Awarding Night, pengumuman juara, sekaligus pembagian hadiah.
          Berikut penjelasan mengenai pre-event dan main event:<br></br>
          <ul className={styles.list}>
            <li className={styles.listContent}>Pre-Event</li>
            <ul>
              <li className={styles.listContent}>
                Pre-event diisi dengan serangkaian challenge dan konten lainnya
                pada instagram impact_itb, yang dipuncaki dengan talkshow
                tentang college life bagi anak SMA.
              </li>
            </ul>
            <li className={styles.listContent}>Main Event</li>
            <ul className={styles.list}>
              Kompetisi yang terdiri atas tiga babak yakni:
              <li className={styles.listContent}>a. Penyisihan</li>
              <li className={styles.listContent}>b. Semifinal (20 Besar)</li>
              <li className={styles.listContent}>c. Final (5 Besar)</li>
              <li className={styles.listContent}>
                dengan catatan semifinal dan final dilaksanakan via video
                conference
              </li>
            </ul>
          </ul>
        </p>

        <p className={styles.content}>
          6. Kapan rangkaian acara IMPACT 2.0 akan dilaksanakan?<br></br>IMPACT
          2.0 akan dilaksanakan mulai dari 16 April hingga Juni dengan rincian:
          <ul className={styles.list}>
            <li className={styles.listContent}>
              Pendaftaran: 16 April 2021 - 23 Mei 2021
            </li>
            <li className={styles.listContent}>Pre-Event: 25 April 2021</li>
            <li className={styles.listContent}>Penyisihan: 30 Mei 2021</li>
            <li className={styles.listContent}>
              Pengumuman Penyisihan: 5 Juni 2021
            </li>
            <li className={styles.listContent}>Semifinal: 26 Juni 2021</li>
            <li className={styles.listContent}>
              Pengumuman Semifinal: 28 Juni 2021
            </li>
            <li className={styles.listContent}>Final: 3 Juli 2021</li>
          </ul>
        </p>

        <p className={styles.content}>
          7. Bagaimana cara mendaftar menjadi peserta STEI IMPACT?<br></br>Cek
          instruksi pendaftaran pada <a href="/petunjuk">link berikut ini</a>.
        </p>

        <p className={styles.content}>
          8. Bagaimana jika tidak memiliki kartu pelajar?<br></br>Kartu pelajar
          dapat diganti dengan surat keterangan sekolah.
        </p>

        <p className={styles.content}>
          9. Bagaimana jika peserta tidak mempunyai email?<br></br>Peserta dapat
          menggunakan email orang tua, guru, wali, ataupun kerabat lainnya.
        </p>

        <p className={styles.content}>
          10. Bagaimana jika saya tidak bisa mengunggah/upload file pada saat
          pendaftaran?<br></br>Harap cek bentukan file. File yang diunggah harus
          berupa file berekstensi <b>.jpeg</b>, <b>.jpg</b> atau <b>.png</b>
        </p>

        <p className={styles.content}>
          11. Bagaimana saya mengetahui bahwa saya sudah resmi menjadi peserta
          lomba STEI IMPACT?<br></br>Jika peserta tidak diterima verifikasinya, 
          maka peserta akan menerima email mengenai alasan peserta tersebut tidak 
          diterima dan cara memperbaikinya. Jika verifikasi diterima, maka peserta 
          tidak akan menerima email. Namun, status tim pada website akan berubah 
          menjadi terverifikasi dengan indikator overview tim akan berwarna biru 
          sehingga peserta diharap untuk memeriksa dashboard secara berkala untuk 
          melihat status verifikasi.
        </p>

        <p className={styles.content}>
          12. Apakah ada jangka waktu untuk melengkapi data diri + apakah ada
          jangka waktu untuk pembayaran?<br></br>Peserta memiliki waktu untuk
          verifikasi data dengan melengkapi data diri serta bukti pembayaran
          hingga 23 Mei 2021 Jam 23:59.
        </p>

        <p className={styles.content}>
          13. Apa saja ketentuan peserta jika ingin mendaftar?<br></br>Cek
          instruksi pendaftaran pada <a href="/petunjuk">link berikut ini</a>.
        </p>
      </div>
      <div className={styles.bar}>
        <Footer />
      </div>
    </div>
  );
};
