import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

export const Title = (props) => {
  return (
    <Typography component="h2" variant="h6" gutterBottom
    style={{
        color: '#A05969',
        fontWeight: 'bolder',
        fontSize: '2em'
    }}
    
    >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;