import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { ROUTES } from "./Routes/const";

const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_URL_TEAMS}`;
const BASE_SEMIFINAL_URL = `${process.env.REACT_APP_API_BASE_URL}/semifinal/teams`;

export const Semifinal = axios.create({
  baseURL: BASE_SEMIFINAL_URL,
  withCredentials: true,
});

export const Teams = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
var source = axios.CancelToken.source();
Teams.interceptors.request.use(
  (config) => {
    if (!Auth.isInitialized) {
      config.cancelToken = source.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const Auth = {
  isAuthenticated: false,
  data: undefined,
  isInitialized: false,
  isVerifying: false,
  init(team) {
    this.isAuthenticated = true;
    this.data = team;
  },
  clear() {
    this.isAuthenticated = false;
    this.data = undefined;
  },
  signout() {
    const self = this;
    return new Promise((resolve, reject) => {
      Teams.post(`/logout`)
        .then((res) => {
          if (res.status === 200) {
            self.clear();
            resolve(true);
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  },
  isVerified() {
    return (
      this.isAuthenticated && (this.data.isEmailVerified || this.data.isAdmin)
    );
  },
  isActivated(){
    return this.isAuthenticated && this.data.teamstatus === "activated";
  },
  isAdmin() {
    return this.isAuthenticated && this.data.isAdmin;
  },
  load() {
    return new Promise((resolve) => {
      return axios
        .post(`${BASE_URL}/getauth`, {}, { withCredentials: true })
        .then((res) => {
          this.init(res.data.team);
          return resolve();
        })
        .catch(() => {
          this.clear();
          return resolve();
        });
    });
  },
};

export const dynamicRoute = {
  show: {
    authenticated: () => {
      return !Auth.isAuthenticated;
    },
    emailverify: () => {
      return Auth.isAuthenticated && !Auth.isVerified();
    },
    verified: () => {
      return Auth.isVerified();
    },
    admin: () => {
      return Auth.isAdmin();
    },
    true: () => {
      return true;
    },
  },
  redirect: {
    auth: () => {
      return Auth.isVerified()
        ? ROUTES.DASHBOARD.PESERTA.OVERVIEW
        : ROUTES.AUTH.VERIFY_RESEND;
    },
    emailverify: () => {
      if (Auth.isAuthenticated) return ROUTES.DASHBOARD.PESERTA.OVERVIEW;
      else {
        alert("Silahkan login terlebih dahulu sebelum melanjutkan.");
        Auth.isVerifying = true;
        return ROUTES.AUTH.LOGIN;
      }
    },
    dashboard: () => {
      return Auth.isAuthenticated
        ? ROUTES.AUTH.VERIFY_RESEND
        : ROUTES.AUTH.LOGIN;
    },
    admin: () => {
      return Auth.isAuthenticated
        ? ROUTES.DASHBOARD.PESERTA.OVERVIEW
        : ROUTES.AUTH.LOGIN;
    },
  },
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const AuthComponent = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const redirect = () => {
      setIsLoading(false);
      if (!dynamicRoute["show"][props.show]()) {
        const redirect = dynamicRoute["redirect"][props.redirect]();
        if (props.show === "emailverify" && redirect === ROUTES.AUTH.LOGIN)
          history.push(redirect);
        else history.replace(redirect);
      } else if (!Auth.isInitialized) {
        Auth.isInitialized = true;
        history.replace(history.location);
      }
    };
    setIsLoading(true);
    if (Auth.isInitialized) {
      redirect();
    } else {
      Auth.load().then(() => {
        redirect();
      });
    }
  }, [props.show, props.redirect, history]);

  return (
    <>
      {props.children}
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export const LogoutComponent = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const Logout = () => {
    if (isLoading) return;
    setIsLoading(true);
    Auth.signout()
      .then(() => {
        setIsLoading(false);
        history.push(ROUTES.AUTH.LOGIN);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div onClick={Logout}>{props.children}</div>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export const VerifyComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Auth.isInitialized) {
      if (Auth.isAuthenticated) {
        setIsLoading(true);
        Teams.put("/verifyemail", { email: Auth.data.email, token: token })
          .then((res) => {
            Auth.data.isEmailVerified = true;
            Auth.isVerifying = false;
            alert("Email berhasil diverifikasi!");
            history.replace(ROUTES.DASHBOARD.PESERTA.OVERVIEW);
          })
          .catch((err) => {
            alert("Terjadi kesalahan ketika verifikasi email.");
            history.replace(ROUTES.AUTH.VERIFY_RESEND);
          });
      }
    }
  }, [Auth.isInitialized, history, token]);

  return (
    <>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <Typography variant="h3">Memverifikasi email...</Typography>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
