import React, { useState } from 'react';
import { Grid, Link } from '@material-ui/core';
import { useHistory, Link as RouteLink } from 'react-router-dom';
import { LayoutForm, TextForm } from "./Layout";
import { Teams, Auth } from '../../api';
import { ROUTES } from "../../Routes/const";

export const Login = () => {
  const history = useHistory();
  const [body, setBody] = useState({
    email: "",
    password: ""
  });
  const [err, setErr] = useState("");
  const [isSendingForm, setIsSendingForm] = useState(false);

  const handleChange = (ev)=>{
    if(err !== '')
      setErr('');
    const value = ev.target.value;
    setBody({
      ...body,
      [ev.target.name]: value
    });
  }

  const submitForm = (ev) => {
    ev.preventDefault();
    if(isSendingForm) return;
    setErr('')
    setIsSendingForm(true);
    Teams.post('login', body)
    .then(res => {
      Auth.init(res.data.team);
      setIsSendingForm(false);
      if(Auth.isVerifying)
        history.goBack();
      else
        history.push(ROUTES.DASHBOARD.PESERTA.OVERVIEW);
    }).catch((err => {
      setIsSendingForm(false);
      if(err.response)
        setErr(err.response.data.err);
      else
        setErr("Timeout! Server not responding or check your connectivity.");
    }))
  }

  return (
    <LayoutForm
      formType="Login"
      submitForm={submitForm}
      isSendingForm={isSendingForm}
      formButtonLabel="Login"
      bottom={
        <>
        <Grid container justify="center">
          <Grid item>
            <Link component={RouteLink} to={ROUTES.AUTH.REGISTER} variant="body2">
                Belum punya akun? Daftar
            </Link>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item>
            <Link component={RouteLink} to={ROUTES.AUTH.FORGOT} variant="body2">
                Lupa password?
            </Link>
          </Grid>
        </Grid>
        </>
      }
    >
      <Grid item xs={12}>
        <TextForm
          required
          autoFocus
          name="email"
          onChange={handleChange}
          value={body.email}
          label="Email"
          error={err !== ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextForm
          required
          type="password"
          name="password"
          onChange={handleChange}
          value={body.password}
          label="Password"
          error={err !== ''}
          helperText={err}
        />
      </Grid>
    </LayoutForm>
  );
}